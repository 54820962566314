.popup-menu {
  z-index: 9999;
  position: fixed;
  top: 0.9vw; /* Start hidden above the viewport */
  right: 1vw;
  opacity: 0;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  animation: shrink 0.4s ease-in-out forwards;
}

.popup-menu.visible {
  opacity: 1;
  transition: all 0.1s ease-in-out;
  animation: grow 0.4s ease-in-out forwards;
}
@keyframes grow {
  from {
    transform: scale(0); /* Start small */
  }
  to {
    transform: scale(1); /* End at full size */
  }
}
@keyframes shrink {
  from {
    transform: scale(1); /* End at full size */
  }
  to {
    transform: scale(0); /* Start small */
  }
}

.hamburger-menu.active {
  width: 5.5vw;
  height: 10vh;
  border-radius: 50%;
  background: #0466c8;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjusted to center the lines vertically */
  align-items: center;
  gap: 0.6rem;
  padding: 0.9rem;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu:before {
  background-color: #0466c8;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}



.hamburger-menu:hover {
  color: white;
  transition: 0.25s;
}

.hamburger-menu:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}
/* .hamburger-menu:hover{
    width: 5.5vw;
    height: 10vh;
    border-radius: 50%;
    background: #0466c8;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: .9rem;
    transition: all 0.3s ease-in-out;

  } */
.hamburger-menu.clicked-menu {
  background: #0466c8;
}


.hamburger-menu:hover .hamburger-line {
  background: white;
  transition: all 0.3s ease-in-out;
}
.hamburger-menu:hover .line-mid {
  background: white;
  width: 50%;
  transition: all 0.3s ease-in-out;
}
.hamburger-line {
  width: 70%;
  height: .2vh;
  background: white;
  transition: all 0.3s ease-in-out;
}
.hamburger-menu.active .line-top {
  transform: rotate(40deg);
  position: relative;
  top: 0.5rem;
  transition: all 0.6s ease-in-out;
}
.hamburger-menu.active .hamburger-line {
  width: 50%;
}

.hamburger-menu.active {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger-menu.active .line-mid {
  display: none;
  transition: all 0.3s ease-in-out;
}
.hamburger-menu.active .line-bottom {
  transform: rotate(-40deg);
  position: relative;
  transition: all 0.6s ease-in-out;

  top: -0.2rem;
}

.popup-menu.clicked {
  display: none;
}
.hamburger-menu {
  position: relative;
  width: 5rem; /* You may adjust this size as needed */
  height: 5rem; /* Set the height equal to the width */
  border-radius: 50%;
  background: #171717;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjusted to center the lines vertically */
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: border-color 0.35s ease, background 0.35s ease;
  transition: border-color 0.35s ease, background 0.35s ease;
}
/* width: 4.5rem; 
  height: 4.5rem;  */

.hamburger-menu.active {
  width: 5rem; /* You may adjust this size as needed */
  height: 5rem; /* Set the height equal to the width */
}

/* Adjust padding to maintain the desired visual appearance */
.hamburger-menu:not(.active) {
  padding: 0.8rem; /* Adjust padding for non-active state */
}

.hamburger-menu.active {
  padding: 0.6rem; /* Adjust padding for active state */
}

/* Hamburger Menu Lines */
.hamburger-line {
  width: 50%;
  height: .2vh;
  background: white;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .popup-menu {
    z-index: 9999;
    position: fixed;
    top: 4.2vw; /* Start hidden above the viewport */
    right: 2vw;
    opacity: 0;
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    animation: shrink 0.4s ease-in-out forwards;
  }
}
@media (max-width: 1200px) {
  .hamburger-menu {
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Adjusted to center the lines vertically */
    align-items: center;
    gap: 0.6rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: border-color 0.35s ease, background 0.35s ease;
    transition: border-color 0.35s ease, background 0.35s ease;
  }

  .hamburger-menu.active {
    width: 4.5rem;
    height: 4.5rem;
  }
}

@media(hover:hover){

  .hamburger-menu:hover:before {
    top: 0;
  }
}

@media(hover:none){

  .hamburger-menu:hover:before {
    top: unset;
  }
}