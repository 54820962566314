.forum-section {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  width: 100vw;
  height: 100svh; /* Change height to auto */
  background: #171717;
  padding: unset;
  position: relative;
  padding: 2rem;
}
.top-section-contact .icons-section {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  gap: 1rem;
  font-size: clamp(1em, 1.2vw, 1.2vw);
}
form .submit-btn {
  position: absolute;
  color: white;
  background: #0466c8;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  bottom: 0;
  right: 5vw;
  font-family: "lobster", sans-serif;
}
.close-btn {
  width: 8vw;
  height: 15vh;
  background: #0466c8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 3vw;
  border: none;
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.top-section-contact {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contacts {
  width: 100%;
  height: 30vw;
  display: flex;
  flex-direction: column;
  font-family: "Noto Sans", sans-serif;
}
.top-section-contact .copyright_txt {
  color: var(--color-one);
  position: absolute;
  top: 2vw;
  cursor: pointer;
}
.contacts h2 {
  font-size: clamp(0.95rem, 1.2vw, 1.2vw);
  font-family: "Noto Sans", sans-serif;
  color: #f0f0f0;
  font-weight: 400;
}
.contact-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  height: 100%;
  gap: 1rem;
  color: var(--color-one);
  font-weight: 100;
  margin-top: 0vw;
}
.contact-info p {
  font-size: clamp(0.8rem, 1.1vw, 1.1vw);
  font-family: "Noto Sans", sans-serif;
}
.top-section-contact h6 {
  font-family: "lobster", sans-serif;
  color: var(--color-one);
  font-size: 5vw;
  font-weight: 300;
  border-bottom: 1px solid rgb(46, 46, 46);
  padding: 1vw 0vw;
}

.right-section-contact {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}

.name-input,
.email-input,
.phone-input,
.message-input {
  position: relative;
  width: 70%;
  height: 5vw;
  margin-top: 1vw;
  color: #f0f0f0;
}

.right-section-contact h1 {
  font-size: clamp(0.9rem, 1.1vw, 1.1vw);
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 1vw;
}

input {
  position: relative;
  font-family: "Noto Sans", sans-serif;
  width: 100%;
  background: transparent;

  border: none;
  border-radius: unset;
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  outline: none;
  color: rgb(191, 191, 191);
  padding: 1vw 2vw;
  font-weight: 200;
}
@media (max-width: 1206px) {
  textarea {
    height: 100%; /* Set textarea height to 100% */
    min-height: 10vh; /* Set a minimum height to ensure visibility */
  }
  .name-input,
  .email-input,
  .phone-input,
  .message-input {
    position: relative;
    width: 70%;
    height: 3rem;
    margin-top: 1rem;
  }
  .top-section-contact .navLink {
    width: 70%;
  }
  .right-section-contact h1 {
    color: white;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 0.9rem;
  }
  input {
    position: relative;
    font-family: "Noto Sans", sans-serif;
    width: 100%;
    outline: none;
    color: white;
    padding: 0.7rem 1rem;
    font-weight: 200;
  }
  .top-section-contact h6 {
    font-family: "lobster", sans-serif;
    color: var(--color-one);
    font-size: 3rem;
    border-bottom: unset;
    padding: 1rem 0;
  }
  form .submit-btn {
    position: relative;

    left: 0;
  }
  .forum-section {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 2fr auto;
    grid-gap: 2rem;
    width: 100vw;
    padding: 2rem 0;
    height: 100%;
  }
  .top-section-contact .copyright_txt {
    position: relative;
    width: fit-content;
    font-size: 0.9rem;
  }

  .top-section-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    gap: 4rem;
  }

  .contacts h2 {
    font-family: "Noto Sans", sans-serif;
    color: #f0f0f0;
    font-weight: 400;
  }
  .right-section-contact {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .contacts {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: "Noto Sans", sans-serif;
  }

  .contact-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
    height: 100%;
    gap: 1rem;
    color: var(--color-one);
    font-weight: 100;
    margin-top: 1rem;
    width: 100%;
  }
  .right-section-contact .submit-btn {
    position: relative;
    color: white;
    background: #0466c8;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    align-self: baseline;
    left: 15vw;
    top: 1rem;
  }
  /* .contacts .icons-bottom .icon-contact-section {
    font-size: 1.5rem;
  } */
  .contacts .icons-section {
    position: relative;
    height: 2rem;
    width: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: unset;
    align-items: unset;
    margin-left: unset;
    top: unset;
    justify-self: unset;
    align-self: unset;
  }
}
.icon-contact-section {
  color: white;
  transition: all 0.3s ease-in-out;
}

@media (hover: hover) {
  .icon-contact-section:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
    color: #0466c8;
    cursor: pointer;
  }
}
@media (hover: none) {
  .icon-contact-section:hover {
    all: unset;
  }
}

@media (width > 1400px) {
   input{
    font-size: 1.3rem;
   }
   form .submit-btn {
    font-size: 1.5rem;
}
.forum-section {
  
  padding: 2rem 3rem;
}
}
