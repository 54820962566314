/* Styling for the side menu container */
.sideMenu {
  position: fixed;
  top: 0;
  height: 100%;
  background: #171717;
  z-index: 9999;
  display: grid;
  grid-template-rows: 0.6fr 2fr 0.5fr;
  /* padding-left: 2vw; */
  overflow: hidden;
  font-family: "Noto Sans", sans-serif;
  right: 0;
}
.sideMenu0midSection{
  text-decoration: none;
  list-style: none;
}
/* Styling for the mid section of the side menu */
.sideMenu-midSection {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  padding: 0 2rem;
}
.sideMenu-midSection nav {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
}
.sideMenu-midSection nav ul li a{
  text-decoration: none;
} 
/* Styling for the navigation within the mid section */
.sideMenu-midSection nav ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 0px;
  position: relative;
  gap: 1.5rem;
}


.about-active {
  text-decoration: none;
}
.contact-active {
  text-decoration: none;
}

/* Styling for individual list items in the navigation */
.sideMenu-midSection li {
  position: relative;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: fit-content;
  justify-self: center;
  align-self: center;
}

/* Styling for the links within the list items */
.sideMenu-midSection nav ul li a {
  font-size: clamp(2.5rem, 3.3vw, 3.3vw);
  font-weight: 300;
  color: white;
  text-decoration: none;
  display: flex;
  position: relative;
  transition: all 0.3s ease-in-out;
  justify-self: center;
  font-family: "Lobster", sans-serif;

}
/* Styling for the close icon */

/* Styling for the close icon on hover */

/* Styling for the top section of the side menu */
.sideMenu-topSection {
  position: relative;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgb(50, 50, 50);
  margin: 0 auto;
}
.sideMenu-bottomSection p {
  position: absolute;
  font-size: clamp(0.8rem, 0.8vw, 0.8rem);

  top: 0;
  left: 0;
  padding: 0.4rem 0.1rem;

  color: rgb(177, 177, 177);
}
.sideMenu-topSection p {
  position: absolute;
  font-size: clamp(0.8rem, 0.8vw, 0.8rem);
  bottom: 0;
  padding: 0.4rem 0.1rem;
  color: rgb(177, 177, 177);
  font-family: "Noto Sans", sans-serif;

}
/* Styling for the bottom section of the side menu */
.sideMenu-bottomSection {
  position: relative;
  display: flex;
  justify-self: center;
  padding-right: 20px;
  width: 80%;
  height: 100%;
}

/* Styling for the underline animation */
.sideMenu-midSection nav ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust as needed */
  width: 0;
  height: 2px; /* Adjust as needed */
  background-color: white;
  transition: width 0.3s ease; /* Add a smooth transition */
  transform-origin: left;
}

/* Triggering the underline animation on hover */
.sideMenu-midSection nav ul li a:hover::after {
  width: 100%;
}

/* Keyframes animation for the underline */
@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.sideMenu-bottomSection ul {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  list-style: none;
  color: rgb(218, 218, 218);
  border-top: 1px solid rgb(50, 50, 50);
  font-size: clamp(0.6rem, 0.8vw, .8rem);

  font-weight: 300;
}

.sideMenu-bottomSection ul li a {
  cursor: pointer;
  transition: all 0.1s ease-in-out; 
   font-family: "Noto Sans", sans-serif;
   color: white;
   text-decoration: none;

}
.sideMenu-bottomSection ul li a:hover {
  transition: all 0.1s ease-in-out;

  color: #0466c8;
}
/* Styling for the close icon */
.sideMenu-closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

/* Apply underline animation on hover */
.sideMenu-midSection nav ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.sideMenu-midSection nav ul li a:hover::after {
  width: 100%;
}

/* Keyframes animation for the underline */
@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.sideMenu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.114); /* semi-transparent black */
  z-index: 9998; /* ensure the overlay is below the side menu */
  transition: all 0.2s ease-in-out;
  backdrop-filter: blur(5px); /* Apply blur effect */
}
@media(max-width:523px){
  .sideMenu-bottomSection ul {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    list-style: none;
    color: rgb(218, 218, 218);
    border-top: 1px solid rgb(50, 50, 50);
  
    font-size: clamp(0.7rem, 0.6vw, .8rem);
  
    font-weight: 300;
  }
}
/* 
@media (max-width: 1200px) {
  .sideMenu-midSection nav ul li a {
    font-size: 2.2rem;
    text-decoration: none;
    display: flex;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 1050px) {
  .sideMenu-midSection nav ul li a {
    font-size: 2rem;
    text-decoration: none;
    display: flex;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 1400px) {
  .sideMenu-midSection nav ul li a {
    font-size: 2.6rem;
    text-decoration: none;
    display: flex;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  .sideMenu-bottomSection ul {
    font-size: 0.75rem;
  }
}
@media (width <= 1300px) {
  .sideMenu-bottomSection p {
    position: absolute;
    font-size: 0.8rem;
    top: 0;
  }
  .sideMenu-topSection p {
    position: absolute;
    font-size: 0.8rem;
    bottom: 0;

    color: rgb(177, 177, 177);
  }
} */

/* @media (width <= 980px) {
  .sideMenu-midSection nav ul {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius: 0px;

    position: relative;
    gap: 1.5rem;
  }
  .sideMenu-bottomSection p {
    position: absolute;
    font-size: 0.6rem;
    top: 0;
    left: 9.5vw;
    color: rgb(177, 177, 177);
  }
  .sideMenu-topSection p {
    position: absolute;
    font-size: 0.6rem;
    bottom: 0;
    left: 19vw;

    color: rgb(177, 177, 177);
  }
  .sideMenu-bottomSection ul {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    list-style: none;
    color: rgb(255, 255, 255);
    font-size: 0.7rem;
    font-weight: 300;
  }
} */

/* Remove underline animation on hover when hover is not supported */
@media (hover: none) {
  .sideMenu-midSection nav ul li a::after {
    width: 0;
  }

  /* Remove color change on hover when hover is not supported */
  .sideMenu-bottomSection ul li:hover {
    color: inherit; /* Or any other desired default color */
  }
}

@media(max-width: 436px){
  .sideMenu-bottomSection ul {

    font-size: .6rem;
   
  }
  .sideMenu-bottomSection ul {

    gap: 1.5rem;
  
  }
}