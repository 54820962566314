@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Red+Hat+Display:wght@500;600;700;800;900&family=Rubik:wght@900&family=Sora:wght@700&family=Unbounded:wght@200;300;900&display=swap");

/*Hero content section -------*/

.wrapper-main {
  position: relative;
  background: var(--color-one);

  padding: 2rem 3.9rem;
  padding-bottom: 4rem;
  margin-bottom: 100px;

  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  overflow-x: hidden;
}

.top-section {
  position: relative;
  max-width: 100%;
  height: 90svh;
  /* max-height: 750px; */
  border-radius: 15px;
  padding: 2rem 5rem;
  /* background: url("../../../public/assets/img/white.png"); */
  background-position: center;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-repeat: no-repeat;
  object-fit: cover;
  max-height: 900px;
}
.button-section {
  width: 100%;
  height: 100%;
}

.top-section .arrow-down-icon {
  position: absolute;
  bottom: 3.5vw;
  right: 6.5vw;

  font-size: 3.3vw;
  transform: rotate(90deg);
  color: #252525;
  font-family: "Humane", sans-serif;
}
.top-section h2 {
  position: absolute;
  bottom: 7rem;
  right: 3vw;
  line-height: 1.4;
  font-size: clamp(0.9rem, 1.3vw, 1.3vw);
  color: #252525;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  width: 25vw;
}

.top-section .copyright_txt {
  position: absolute;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  color: #171717;

  align-items: center;
  gap: 0.3rem;
  font-size: clamp(0.9rem, 1.2vw, 1.4rem);
  top: 2rem;
  transition: all 0.3s ease-in-out;

  white-space: nowrap;
}
.top-section-corner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 5vh;
  background: var(--color-one);
  bottom: -0.4rem;
  left: -0.1rem;
  border-top-right-radius: 1em;
  box-shadow: -3px -3px 3px -3px rgba(0, 0, 0, 0),
    /* Negate shadow on the left */ 3px 0px 3px -3px rgba(27, 31, 35, 0.15),
    /* Right shadow */ 0px -3px 3px -3px rgba(27, 31, 35, 0.15); /* Top shadow */
  padding-top: 0.5rem;
}
.top-section-corner p {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
}
.top-section-corner::after {
  position: absolute;
  bottom: -0.75rem;
  right: -0.85rem;
  content: " ";
  width: 30px;
  height: 30px;
  --border-radius: 50%; /* Increase border-radius for a bigger circle */
  --corner-size: 0.6; /* Adjust corner size accordingly */
  --color-light: var(--color-one); /* Example value for light color */

  background-image: radial-gradient(
    circle at 100% 100%,
    transparent calc(var(--border-radius) * var(--corner-size)),
    var(--color-light) calc((var(--border-radius) * var(--corner-size)) + 0.5px)
  );
  transform: rotate(270deg); /* Adjust rotation and positioning */
}
.top-section-corner::before {
  position: absolute;
  top: -0.81rem;
  left: -0.98rem;
  content: " ";
  width: 30px;
  height: 30px;
  --border-radius: 50%; /* Increase border-radius for a bigger circle */
  --corner-size: 0.6; /* Adjust corner size accordingly */
  --color-light: var(--color-one); /* Example value for light color */

  background-image: radial-gradient(
    circle at 100% 100%,
    transparent calc(var(--border-radius) * var(--corner-size)),
    var(--color-light) calc((var(--border-radius) * var(--corner-size)) + 0.5px)
  );
  transform: rotate(270deg); /* Adjust rotation and positioning */
}

.top-section_nav {
  position: absolute;
  top: 2rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
  margin-right: 3.9rem;
  font-size: clamp(0.9rem, 1.2vw, 1.4rem);
  color: #171717;
  background: transparent;
  font-family: "Noto Sans", sans-serif;
  gap: 0.3rem;
}
.top-section_nav .about-me {
  position: relative;
  color: #171717;
  border: none;
  outline: none;
  cursor: pointer;
}
.contact-link {
  position: relative;
  color: #171717;
  border: none;
  outline: none;
  cursor: pointer;
}
.contact-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1.5px;
  background-color: #0466c8;
  transition: width 0.3s ease;
}
.contact-link:hover::before {
  width: 100%;
}
.top-section_nav a {
  position: relative;
  cursor: pointer;
}

/* .main-nav {
  position: absolute;
  left: 0;
  top: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}
.main-nav ul {
  background: none;
}
.main-nav ul li {
  background: none;
  font-size: 1.2vw;
} */
.top-section h1 {
  line-height: 1;

  font-size: clamp(2rem, 6.7vw, 6.7vw);
  font-weight: 700;
  color: #252525;
  font-family: "Lobster", sans-serif;
  position: relative;
  bottom: 1.7rem;
  width: 120%;
}
.top-left-content {
  position: absolute;
  bottom: 11vh;
  height: 20rem;
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bottom-section-of-hero-section {
  position: relative;
  /* font-size: clamp(1.1rem, 1.7vw, 1.7vw); */
  font-size: clamp(1rem, 1.5vw, 1.5vw);
  bottom: 0rem;
  width: clamp(20rem, 28vw, 28vw);

  display: flex;
  justify-content: space-around;
  align-items: center;
}

.about-me {
  padding: 1vw 1.5vw;
  color: white;
  background: #252525;
  font-size: clamp(0.6rem, 1vw, 1vw);
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  outline: none;
  border: none;
  margin-left: 1vw;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about-me_arrow {
  font-size: 1rem;
  left: 0.5rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: translateX(0%);
  border-radius: 50%;
}
.showcase-btn_arrow {
  font-size: 1rem;
  left: 0.5rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  transform: translateX(0%);
  border-radius: 50%;
}

.about-me:hover .about-me_arrow {
  color: #171717;
  background: transparent;
  transition: all 0.3s ease-in-out;
  transform: translateX(100%) scale(1.7);
  background: white;
  border-radius: 50%;
}

.bottom-section-of-hero-section .about-me:hover {
  color: white;
  background: #0466c8;
  transition: all 0.3s ease-in-out;
}

/*About content section -------*/

.about_author_section {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  top: 3rem;
  border-radius: 15px;
  height: clamp(400px, 32vw, 32vw);

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  text-decoration: none;
}
.about-author_arrow {
  font-size: clamp(2.5rem, 3.5vw, 3.5vw);
  color: #171717;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  transition: all 0.3s ease-in-out;
  transform: translateX(-140%);
  z-index: 12;
}

.about-author_content {
  border-radius: 15px;
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../public/assets/img/icon2.png");
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.get-to-know-me {
  position: relative;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans", sans-serif;
  padding: 2rem 1rem;
}

.get-to-know-me .item {
  width: 100%;
  height: 25%;
  border-top: 1px solid rgb(238, 238, 238);
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.border-top-none {
  width: 100%;
  height: 20%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.get-to-know-me .item-title {
  width: 100%;
  height: 100%;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: clamp(0.8rem, 1.2vw, 1.2vw);
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  text-decoration: none;
  list-style: none;
  color: #252525;
  padding: 0;
}
.get-to-know-me .item-description {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: clamp(0.6rem, 0.9vw, 1vw);
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #252525;
  line-height: 1.6;
}

.about-author h1 {
  position: absolute;
  left: 10%;
  top: 50%;
  width: 10vw;
  border-radius: 15px;
  font-size: 3vw;
  color: white;
  background: none;
  padding: 0.8vw 1vw;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
}
.about-author h1:hover {
  color: #0466c8;
  transition: all 0.3s ease-in-out;
}

.about-author:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

/*skills-section -------------------------------------------------------------------*/

.skills-section {
  position: relative;
  width: 100%;
  height: 20vw;
  top: 6rem;
  display: flex;
  gap: 1.5rem;
}
.skills-section > div {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.technologies {
  cursor: pointer;
  background: #171717;
  position: relative;
  border-radius: 15px;
  flex: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.technologies .icon {
  color: white;
  font-size: 3.5vw;
  position: absolute;
  top: 2.5vw;
  right: 4vw;
  transform: rotate(180deg);
}
.technologies h1 {
  position: relative;
  top: 2.2vw;
  left: 2.5vw;
  border-radius: 15px;
  background: white;
  color: #171717;
  padding: 0.7vw;
  font-weight: 300;
  font-size: clamp(0.7rem, 1vw, 1.2rem);
  width: 12vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Noto Sans", sans-serif;
}
.click-icon {
  display: none;
  opacity: 0;
}
.technologies p {
  position: absolute;
  bottom: 2vw;
  left: 3vw;
  font-size: clamp(1.6rem, 2vw, 5rem);

  font-weight: bolder;
  font-weight: 400;
  color: white;
  font-family: "Noto Sans", sans-serif;
}

.technologies > p:last-child {
  display: flex;
  flex-direction: column;
  /* height: 2.5vw; */
  overflow: hidden;
}
.technologies > p:last-child span:first-child {
  transform: translateY(0%);
  transition: all 0.3s ease-in-out;
}
.technologies > p:last-child span:last-child {
  position: absolute;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
}

.technologies .icon-html {
  width: 2vw;
  height: 1.5vw;
  border-radius: 50%;
  background: white;
  padding: 0.1rem;
  color: #252525;
}

.soft-skills_intro .soft-skills-title {
  position: relative;
  top: 4rem;
  left: 1.6rem;

  color: #f9f9f9;
  width: fit-content;
  /* max-width: 18rem; */
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: hidden;
}
/* .expanded_tech_grid ~ .soft-skills-title.moved-up{
  background: #0466c8;
} */
.technologies.active ~ .soft-skills .soft-skills-title.mover-up {
  background: #535353;
}

@media (max-width: 1048px) {
  .soft-skills_intro .soft-skills-title {
    width: fit-content;
  }
  .positive-span h1 {
    position: relative;
    transform: translateX(0%) scale(1);
    color: white;
    transition: all 0.3s ease-in-out;
    font-size: 6rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: 400;
  }
}

.span-line-negative {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: white;
  transition: all 0.2s ease-in-out;
  transform: translateX(-200%);
}
.span-line-positive {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: white;
  transition: all 0.2s ease-in-out;
}

.positive-span h1 {
  position: relative;
  transform: translateX(0%) scale(1);
  color: white;
  transition: all 0.3s ease-in-out; /* Added delay here */
  font-size: clamp(2rem, 3vw, 6rem); /* Default font size */
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}
.negative-span {
  position: absolute;
}

.negative-span h1 {
  position: relative;
  transform: translateX(-200%);
  color: white;
  transition: all 0.3s ease-in-out;
  font-size: clamp(2rem, 3vw, 6rem); /* Default font size */
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}

.soft-skills {
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px;
  flex: 1;
  transition: all 1s ease-in-out;
  cursor: pointer;
  background: #171717;
  overflow: hidden;
}
.soft-skills.active::after {
  right: -50rem;
  transition: all 1s ease-in-out;
  opacity: 0;
}

.icon-arrow-askew {
  position: absolute;

  padding: 0.3rem;
  top: 1vw;
  right: 0.7vw;
  border-radius: 50%;
  color: white;
  background: black;
  font-size: 2.5vw;
  transition: all 0.4s ease-in-out;
}
.soft-skills,
.technologies {
  transition: all 0.3s ease-in-out;
  flex-grow: 1.2;
}
.technologies:hover .icon {
  transition: all 0.3s ease-in-out;
  color: #0466c8;
}
.soft-skills:hover .icon-arrow-askew {
  color: white;
  background: #0466c8;
  transition: all 0.4s ease-in-out;
  transform: rotate(45deg);
}
.soft-skills .hover-text {
  position: absolute;
  bottom: 10%;
  left: 3vw;
  font-size: clamp(1.6rem, 2vw, 5rem);
  font-weight: bold;
  font-weight: 400;
  color: #ffffff;
  font-family: "Noto Sans", sans-serif;
}
.hover-text {
  position: absolute;
  transition: all 0.1s ease-in-out;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.soft-skills.active {
  flex-grow: 1.5;
  background: #171717;
}
.soft-skills-inner-grid {
  width: 100%;
  height: 100%;
  display: flex;
}

.soft-skills_text_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.technologies.active {
  flex-grow: 2;
}

.left-btn {
  position: relative;
  /* right: 2vw; */
}
.right-btn {
  position: relative;
  /* left: 2vw; */
}

.softSkills_btn {
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(245, 245, 245, 0.544);
  color: whitesmoke;
  padding: 0.7rem;
  width: 2.5rem;
  height: 2.5rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.softSkills_btn:hover {
  transition: all 0.3s ease-in-out;
  background: #0466c8;
  color: white;
  border: 1px solid #0466c8;
}
.soft-skills.active h1 {
  text-align: center;
  font-size: 2.5vw; /* Default font size */
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
}
.soft-skills.active p {
  font-size: 1vw;
  font-weight: 300;
  color: rgb(243, 243, 243);
  text-align: center;
  width: 85%;
  font-family: "Noto Sans", sans-serif;
}
.technologies .brief_description_of_tech_section {
  font-size: clamp(0.8rem, 1.1vw, 3rem);
  position: absolute;
  top: 50%;
  width: 45%;
  line-height: 1.5;
  font-weight: 300;
  color: white;
}

/*Project content section -----------------------------------------------------*/

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.no-scroll {
  overflow: hidden;
}
.video-container video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  opacity: 0.1;
}

.email-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.892);
  z-index: 999; /* Adjust z-index if needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.892); /* Red overlay with 50% opacity */
  border-radius: 15px;
}
html {
  font-size: 62, 5%;
}

.pop-up-nav {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
  z-index: 9999; /* Ensure it's above other content */
}

.location-icon {
  color: white;
  font-size: 20px;
  z-index: 3;
}

.image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  /* background: url("../../../public/assets/img/me2.png"); */
  background-position: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.image:hover {
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
  flex-grow: 4;
}

.icons {
  border-radius: 15px;
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;

  position: relative;
  width: 73%;
  height: 100%;
  background: rgb(255, 255, 255);
  position: relative;
  grid-column: 3;
  grid-row: 1;
  margin-left: 145px;
  border-radius: 10px;
}

.icons-contact {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 40px;
}

.icon {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: rgb(37, 37, 37);
}

.icon:hover {
  transition: all 0.3s ease-in-out;
  color: #0466c8;
}

.achievements {
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  grid-row: 3 / span 4;
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
}

.arrow-icon {
  position: absolute;
  bottom: 20px;
  right: 10px;
  font-size: 35px;
  color: #252525;
}
.display {
  color: white;
  position: absolute;
  left: -1%;
  top: 44%;
  font-size: 40px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 999;
}
.line-icons {
  width: 2rem;
  height: 0.2vw;
  background: #171717;
}

.project-item:hover .display {
  z-index: 999;
  color: #171717;
  position: absolute;

  left: 1%;
  font-size: 40px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.projects {
  position: relative;
  width: 100%;
  /* height: clamp(400px, 40svh, 40svh); */
  /* height: clamp(600px, 100vh, 100vh); */
  gap: 3rem;
  display: grid;
  grid-template-rows: 100px 1fr;
  top: unset;
  /* top: 10rem; */
  /* margin-top: 10rem;  */
  padding-bottom: 0rem;
  margin-top: 10rem;
}

.show-cases {
  width: 100%;
  height: clamp(550px, 55svh, 55svh);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pick-project-section .active {
  background: #0466c8;
  color: white;
  transition: all 0.2s ease-in-out;
}
.pick-project-section div:first-child {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  border-right: 1px solid rgba(234, 234, 234);
}
.project-title {
  position: relative;

  font-size: 1.3vw;
  color: #171717;

  font-family: "Lobster", sans-serif;
  font-weight: 300;
}
.project-item {
  position: relative;
  z-index: 11;
  top: 40px;
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid rgb(209, 209, 209);
}
.project-item h1 {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 50px;
  justify-content: center;
  align-items: center;
}
.project-item p {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projects-text {
  position: relative;
  grid-column: 1;
  grid-row: 1;
  padding: 10px;
}

/*SOFT SKILLS-------------------------------------------*/
.soft-skills-grid {
  max-width: 100%;
  position: relative;
  height: 100%;
  background: transparent;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soft-skill-item {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
}
.soft-skills-btns {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.soft-skills .soft-skill-item .soft-skill-elaboration {
  font-size: 0.9rem;
  font-weight: 300;
}

.line-over-soft-skill {
  width: 80%;
  height: 1px;
  background: #17171763;
  margin-bottom: 4vw;
}
.soft-skills-number {
  font-size: 0.8rem;
  color: gray;
}

.bottom-section-of-the-page-menu,
.bottom-section-of-the-page-grid {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: relative;
}

.box-example {
  position: relative;
  width: 80%;
  height: 100%;
  background: rgba(245, 245, 245, 0.656);
  border-radius: 10px;
  overflow: hidden;
  max-height: 600px;
  /* top: 4vw; */
  font-family: "Noto Sans", sans-serif;
  gap: 1rem;
  bottom: unset;

  border: 1px solid rgba(234, 234, 234, 0.929);
}
.img-div img {
  width: 100%;
  height: 90%;
  border-top-left-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  z-index: 3;
  background-position: left;
  object-fit: cover;
  object-position: left;
}
.img-div {
  position: absolute;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  right: 0;
}

.img-back {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 120%;
  background: #171717;
}
.img-div img:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.imgfalse {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  left: 0;
  width: 50%;
  height: 90%;
  bottom: -140px;
  transform: rotate(240deg);
  background: url("../../../public/assets/img/guessthenumber.png") no-repeat
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  opacity: 0.03;
}
.button-section {
  position: relative;
  z-index: 3;
  left: 0;
  top: 0;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  display: flex;
  justify-self: center;
}

.button-section button {
  outline: none;
  background: #171717;
  color: white;
  padding: 1vw 1.5vw;
  border: none;
  font-size: 1.1vw;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}
.button-section {
  display: flex;
  flex-direction: row;
  justify-content: first baseline;
  width: 100%;
  height: 100%;
}

.next-project-arrow {
  position: absolute;
  right: 1vw;
  border-radius: 50%;
  background: rgb(248, 248, 248);
  border: 1px solid rgba(234, 234, 234, 0.929);
  padding: 1rem;
  display: flex;
  justify-self: center;
  align-self: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  z-index: 888;
  font-size: 1.5vw;
  transform: translateY(50%);
}
.prev-project-arrow {
  position: absolute;
  left: 1vw;
  border-radius: 50%;
  background: rgb(248, 248, 248);
  border: 1px solid rgba(234, 234, 234, 0.929);
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-self: center;
  align-self: center;
  cursor: pointer;
  z-index: 888;
  font-size: 1.5vw;
  transform: translateY(50%);
}
.prev-project-arrow:hover,
.next-project-arrow:hover {
  transition: all 0.3s ease-in-out;
  background: #0466c8;
  color: white;
  border: 1px solid #0466c8;
}
/* .prev-project-arrow
.next-project-arrow:hover {
  transition: all 0.3s ease-in-out;
  background: #0466c8;
  color: white;
} */
.hiddenItem {
  display: none;
}
.activeItem {
  display: block;
  display: flex;
  flex-direction: row;
}

.project_description {
  position: relative;
  width: 90%;
  font-size: clamp(0.8rem, 1.1vw, 1.1vw);
  color: gray;
  font-weight: 200;
  line-height: 2;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  align-self: center;
  height: min-content;
  justify-content: flex-start;
  align-items: center;
}

.tools {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  align-items: center;
  gap: 1rem;
}
.tools .tool_icons {
  font-size: clamp(1.5rem, 1.9vw, 1.9vw);
  display: flex;
  align-self: center;
}
.text h1 {
  position: relative;
  font-size: clamp(2.7rem, 3.5vw, 3.5vw);
  color: #171717;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Lobster", sans-serif;
  justify-content: center;
}

.btn {
  position: relative;
  border-radius: 15px;
  width: max-content;
  height: 3rem;
  border: none;
  color: #171717;
  cursor: pointer;
  text-decoration: none;
  font-size: clamp(0.75rem, 1.1vw, 1.1vw);
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  white-space: nowrap;
  background: transparent;
  z-index: 0;
  font-family: "Noto Sans", sans-serif;
  transition: all 0.4s ease-in-out;
}
.pick-project-section {
  position: relative;
  display: grid;

  /* grid-template-columns: 100px auto 100px; */
  grid-template-columns: 100px auto 100px;

  align-items: center;
  justify-self: center;
  align-self: center;
  padding: 1rem;
  gap: 0rem;
  border-radius: 15px;
  border: 1px solid rgba(234, 234, 234);
  width: 70%;
}

.btn-active {
  position: relative;
  color: #0466c8;
  font-weight: 500;
  position: relative;
  border-radius: 15px;
  width: auto;
  height: 3rem;
  font-size: clamp(0.75rem, 1.1vw, 1.1vw);
  white-space: nowrap;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  overflow: visible;
  z-index: 0;
  font-family: "Noto Sans", sans-serif;
  transition: all 0.4s ease-in-out;
}

.project-list-length {
  position: absolute;
  color: #797979;
  top: 0;
  right: -0.8rem;
  font-size: 0.7rem;

  transition: all 0.4s ease-in-out;
}

.layout-icon {
  cursor: pointer;
}
.pick-project-section > div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.layout-works {
  display: flex;
  gap: 1rem;
  height: 100%;
  width: 100%;
  font-size: 1.4vw;
  justify-content: center;
  align-items: center;
}
.layout-icon-active {
  color: #0466c8;
}
.show-cases-menu-layout {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
}
.show-cases-menu-layout ul {
  position: relative;
  margin-top: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
}

.show-cases-menu-layout ul li {
  position: relative;
  height: 20vh;
  width: 100%;
  font-weight: 400;
  border-top: 1px solid rgba(234, 234, 234);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 2rem;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.text {
  position: relative;
  left: 0;
  top: 0;
  width: 50%;
  height: fit-content;
  padding: 2rem;
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-self: center;
  grid-gap: 2.5rem;
  align-self: center;
}

.show-cases-menu-layout ul li h1 {
  position: relative;
  font-weight: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.5vw;
  left: 20%;
  width: 80%;
  font-family: "Lobster", sans-serif;
}
/* .text h1 {
  position: relative;
  font-size: 3.5vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
} */
.show-cases-menu-layout ul li p {
  position: relative;
  align-self: center;
  font-size: 1vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 20%;
  font-family: "Noto Sans", sans-serif;
}
.show-cases-menu-layout {
  overflow: visible;
}
.show-cases-menu-layout ul li button {
  border: none;
  width: fit-content;
  padding: 0.6rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  left: 20%;
}
.show-cases-menu-layout div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 2rem;
  margin: 0;
  font-size: 0.8vw;
  font-family: "Noto Sans", sans-serif;
}
.show-cases-menu-layout div p {
  position: relative;
  font-family: "Noto Sans", sans-serif;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(85, 85, 85);
  margin-bottom: 0.8rem;
  left: 20%;
}
.prjoect-vid-onhover {
  position: absolute;
  /* border-radius: 10px; */

  display: none;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.show-cases-menu-layout li:hover .prjoect-vid-onhover {
  display: block;
  position: absolute;
  width: 20vw;
  height: 20vh;
  /* background: black; */
  right: 0rem;
  bottom: -1vw;
  z-index: 678;
  animation: grow 0.4s ease-in-out forwards;
  background-position: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.show-cases-menu-layout li:hover {
  color: #b3b3b3;
  padding: 0 3rem;
  transition: all 0.4s ease-in-out;
}
/* .show-cases-menu-layout li:hover .h1-one-hover {
  color: #b3b3b3;
} */
.show-cases-menu-layout ul li:hover {
  padding: 0 0rem;
  transition: all 0.4s ease-in-out;
}

@keyframes grow {
  from {
    transform: scale(0); /* Start small */
  }
  to {
    transform: scale(1); /* End at full size */
  }
}
.project-list-length-grid-menu {
  position: relative;
  color: #0466c8;
  top: -0.5rem;
  left: 0.2rem;
}

.name_txt {
  padding-right: 10px;
}
@media (max-width: 1206px) {
  .skills-section {
    width: 100%;
    height: 15rem;
    display: flex;
    gap: 1.5rem;
  }
}

@media (max-width: 1098px) {
  .icon-arrow-askew {
    position: absolute;

    padding: 0.3rem;
    top: 1rem;
    right: 0.7rem;
    border-radius: 50%;
    color: white;
    background: black;
    font-size: 2rem;
  }
  .hover-text.hide {
    display: none !important;
  }
  .technologies .icon {
    color: white;
    font-size: 3rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    transform: rotate(180deg);
  }
  .soft-skills.active ~ .technologies .brief_description_of_tech_section {
    display: none;
  }
  .technologies.active ~ .soft-skills-title {
    display: none;
  }

  .button-section button {
    outline: none;
    background: #171717;
    color: white;
    padding: 0.7rem 1.2rem;
    border: none;
    font-size: 0.8rem;
    border-radius: 10px;
    cursor: pointer;
  }
  /* .text h1 {
    position: relative;
    font-size: 4vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  } */
  /* .project_description {
    position: relative;
    width: 100%;
    height: auto;
    font-size: 0.8rem;
    color: gray;
    font-weight: 200;
    line-height: 2;
    display: flex;
    justify-self: center;
    align-items: center;
  } */

  .bottom-section {
    width: 100%;
    height: auto;
    padding: 2rem 1rem;

    margin-top: 20px;
  }

  .about-author {
    width: 100%;
    height: 60vh;
    border-radius: 15px;

    position: relative;
    overflow: hidden;
    background: url("../../../public/assets/img/icon.png");
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2vw;

    transform: scale(1);
    transition: all 0.3s ease-in-out;
  }

  /* .copyright_txt {
    font-size: 1rem;
    width: 100vw;
  } */
  /* .top-section h1 {
    position: absolute;
    line-height: 1;
    width: 100%;
    font-size: 5.5rem;
    bottom: 20%;
  } */
  /* .bottom-section-of-hero-section {
    position: relative;
    font-size: 1.9rem;
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
  } */
  /* .bottom-section-of-hero-section .about-me {
    padding: 0.9vw 1.5vw;
    color: white;
    background: #252525;
    font-size: 0.85rem;
    border-radius: 15px;
    outline: none;
    border: none;
    margin-left: 0.5vw;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-self: center;
  } */

  /* .project-title {
    font-size: 0.7rem;
  } */
  .pick-project-section > .btn {
    border-radius: 15px;
  }

  .pick-project-section .btn:first-of-type {
    transition: all 0.4s ease-in-out;
  }
  .pick-project-section .btn-active:first-of-type {
    transition: all 0.4s ease-in-out;
  }
  .layout-icon {
    cursor: pointer;
    font-size: 1rem;
  }
  .layout-works {
    display: flex;
    gap: 1rem;
  }
  .project-title {
    position: relative;
    display: flex;
    justify-self: center;
    align-self: center;
    font-size: 1rem;
    padding: 1vw 1.5vw;
    color: #171717;
    right: 1vw;

    font-family: "Lobster", sans-serif;
    font-weight: 300;
  }
  .description-soft-skills {
    font-size: 3.5rem;
    font-family: "Noto Sans", sans-serif;
  }
  /* .pick-project-section {
    width: 50%;
    background: #171717;
  } */
  .left-btn,
  .right-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 6rem;
    color: black;
    z-index: 99;
  }
  .left-btn {
    position: relative;
  }
  .right-btn {
    position: relative;
    /* right: 1rem; */
  }
  .softSkills_btn {
    border-radius: 50%;
    background: transparent;
    border: 1px solid whitesmoke;
    padding: 0.7rem;
    height: 2.5rem;
    width: 2.5rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .soft-skills.active .brief_description_of_tech_section {
    display: none;
  }
  .pick-project-section {
    width: 80%;
  }
}
@media (max-width: 1098px) {
}
@media (max-width: 1070px) {
  /* .pick-project-section {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
   background: red;
    transition: all 0.4s ease-in-out;
  } */

  .btn {
    position: relative;
    border-radius: 15px;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;

    z-index: 0;
    transition: all 0.4s ease-in-out;
  }

  .layout-icon {
    cursor: pointer;
    font-size: 1.2rem;
  }
}
@media (width <= 1096px) {
  .pick-project-section {
    width: 70vw;
  }
  .bottom-section {
    margin-top: 0px;
  }
  .skills-section {
    width: 100%;
    height: 30rem;
    display: flex;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .skills-section .brief_description_of_tech_section {
    width: 23rem;
  }
  .soft-skills.active p {
    font-size: 0.8rem;
  }
  .technologies h1 {
    width: 8rem;
    height: 2.2rem;
    position: relative;
    top: 1.6rem;
    left: 1.6rem;
  }
  .technologies p {
    position: absolute;
    bottom: 1.4rem;
    left: 1.5rem;
    font-weight: bolder;
    font-weight: 400;
    color: white;
  }
  .soft-skills .hover-text {
    position: absolute;
    bottom: 1.4rem;
    left: 1.5rem;
    font-weight: bold;
    font-weight: 400;
    color: #ffffff;
  }
  .soft-skills.active h1 {
    font-size: clamp(1rem, 3vw, 3vw);
  }
  .about-author {
    position: relative;
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: url("../../../public/assets/img/icon2.png");

    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    transition: all 0.3s ease-in-out;
  }

  .bottom-section-of-the-page-grid,
  .bottom-section-of-the-page-menu {
    width: 100%;
    height: 10rem;
    border-radius: 15px;
    position: relative;
    margin-inline: auto;
    margin-bottom: 1rem;
    display: flex;
    justify-self: center;
    align-self: center;
  }
}

@media (width <= 925px) {
  .top-section .arrow-down-icon {
    position: absolute;
    bottom: 4.5vw;
    font-size: 4.3vw;
    transform: rotate(90deg);
    color: #252525;
    font-family: "Humane", sans-serif;
  }
}

/* @media (width <= 873px) {
  .pick-project-section > .btn .project-list-length {
    transition: all 0.4s ease-in-out;
  }
} */
@media (width <= 862px) {
  .about-author {
    width: 100%;
    height: 60vh;
    border-radius: 15px;

    position: relative;
    overflow: hidden;
    background: url("../../../public/assets/img/icon2.png");
    background-position: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 2vw;

    transform: scale(1);
    transition: all 0.3s ease-in-out;
  }
  /* .get-to-know-me .item-title {
    font-size: 0.8rem;
  } */
  /* .get-to-know-me .item-description {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 0.6rem;

    line-height: 1.3;
  } */
}
@media (width <= 860px) {
  .bottom-section-of-the-page-grid {
    width: 100%;
    height: 10rem;
    border-radius: 15px;
    position: relative;
    bottom: 1rem;
    display: flex;
    justify-self: center;
    align-self: center;
  }

  .contact-section-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (width <= 829px) {
  .bottom-section-of-the-page-grid {
    width: 100%;
    height: 10rem;
    border-radius: 15px;
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    justify-self: center;
    align-self: center;
  }
  .contact-section-form {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 2rem;
    position: relative;
    font-family: "Noto Sans", sans-serif;
    height: 4.5rem;
  }
}

@media (max-width: 845px) {
  .top-section .copyright_txt {
    font-size: clamp(0.9rem, 2vw, 1.4rem);

    white-space: nowrap;
  }
  .top-section_nav {
    font-size: clamp(0.9rem, 2vw, 1.4rem);
  }
  .top-section {
    padding: unset;
    height: 95svh;
    /* max-height: 700px; */
  }
  .projects {
    top: unset;
    margin-top: 7rem;
  }
  .wrapper-main {
    position: relative;

    padding: 1rem 1rem;
  }
  .skills-section {
    width: 100%;
    height: 30rem;
    display: flex;
    gap: 1.5rem;
    margin: unset;
    top: 4rem;
  }
  .top-section .copyright_txt {
    left: 5vw;
  }
  .top-section .contact-link {
    margin-right: 0;
    left: 2rem;
  }

  .about_author_section {
    top: 2rem;
  }
  /* .get-to-know-me {
    position: absolute;
    right: 0;
    height: 22rem;
    width: 50%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 3vw;
  }
  .get-to-know-me .item {
    width: 100%;
    height: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  } */

  .marquee-div {
    position: relative;
    width: 100%;
    height: auto;
    top: 45%;
  }
  .top-section h1 {
    position: relative;
    line-height: 1.15;

    font-size: 8rem;
    color: #252525;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
  }

  /* .bottom-section-of-hero-section {
    position: absolute;
    bottom: -5.5vw;
    font-size: 1.7rem;
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 7rem;
  } */

  .top-section .arrow-down-icon {
    position: absolute;
    bottom: 3rem;
    left: 5.5vw;
    font-size: 3rem;
    transform: rotate(180deg);
    color: #252525;
  }
  .top-section h2 {
    position: absolute;
    color: #252525;
    line-height: 1.5;
    left: 5vw;
    font-size: 1rem;
    width: 21rem !important;
    height: 5rem;
    bottom: -6rem;
  }
}
/* @media (width <= 830px) {
  .bottom-section-of-hero-section {
    position: absolute;
    bottom: -5vw;
    font-size: 1.7rem;
    width: 45vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 6rem;
  }
} */
@media (width <= 834px) {
  .pick-project-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
  }

  /* .pick-project-section > .btn {
    position: relative;
    border-radius: 15px;
    width: 7rem;
    height: 3rem;
  }
  .btn-active {
    width: 7rem;
    height: 3rem;
  } */
  .project-title {
    font-size: 0.9rem;
  }
  .bottom-section-of-the-page-grid {
    width: 100%;
    height: 25vh;
    border-radius: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

@media (width <= 753px) {
  .pick-project-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.5rem;
  }
  .technologies p {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    font-weight: bolder;
    font-weight: 400;
    color: white;
  }

  .pick-project-section > .btn {
    position: relative;
    border-radius: 15px;

    background: none;
    border: none;
    cursor: pointer;
    text-decoration: none;

    display: inline-block;
    overflow: hidden;
    z-index: 0;
    transition: all 0.4s ease-in-out;
  }
  .pick-project-section > .btn.project-list-length {
    color: #0466c8;
  }

  .layout-icon {
    cursor: pointer;
    font-size: 1.2rem;
  }
}

@media (width <= 845px) {
  .layout-icon {
    display: none;
  }
  .pick-project-section {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
}

@media (width <= 624px) {
  .pick-project-section > div:nth-child(2) {
    width: auto;
    position: relative;
  }
  .pick-project-section {
    height: 5.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    gap: 0rem;
    align-items: center;
    width: 90%;
  }

  .project-title {
    display: none;
  }
  .pick-project-section::after {
    position: absolute;
    content: "Works";
    width: 20%;
    height: 25%;
    font-size: 1.5rem;
    color: #171717;

    font-family: "Lobster", sans-serif;
    color: #171717;
    background: #fafafa;
    top: -12%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
  }
  .technologies.shrink .brief_description_of_tech_section {
    width: 70%;
  }
}
@media (width <= 845px) {
  .layout-works {
    display: none;
  }
}

@media (width <= 814px) {
  .soft-skills.active h1 {
    text-align: center;
    font-size: clamp(1.5rem, 3.5vw, 3.5vw); /* Default font size */
  }
  .about-author_arrow {
    color: white;
  }
  .border-top-none {
    width: 100%;
    height: 3.7rem;
    display: grid;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }
  .soft-skill-item {
    padding: 4rem 2rem;
  }
  .soft-skills.active p {
    font-size: 0.8rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
  }
  .about-author_content {
    position: relative;
    background: unset;
  }
  .about_author_section {
    width: 100%;
    height: 22rem;

    border-radius: 15px;

    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
  }

  .about_author_section::after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #171717ea;
  }
  .about_author_section::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: unset;
    background-image: url("../../../public/assets/img/emoji.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(8px); /* Adjust the blur radius as needed */
  }

  .get-to-know-me {
    position: absolute;
    right: 0;
    width: 100%;
    height: 22rem;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 0 3vw;
  }
  .why_me_txt {
    display: none;
    opacity: 0;
  }
  .get-to-know-me .item-title {
    width: 100%;
    height: 100%;
    position: relative;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    color: white;
  }
  .get-to-know-me .item-description {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    line-height: 2;
  }
  .get-to-know-me .item {
    width: 100%;
    height: 4rem;
    border-top: unset;
    display: grid;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }

  .soft-skills .hover-text {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    font-weight: bold;
    font-weight: 400;
    color: #ffffff;
  }
  .soft-skills_intro h6 {
    position: relative;
    top: 3rem;
    left: 1.5rem;
    width: fit-content;
    font-weight: 400;
    color: #f9f9f9;
  }
  .technologies .brief_description_of_tech_section {
    position: relative;
    top: 4.5rem;
    width: 22rem;
    line-height: 1.5;
    font-weight: 200;
  }
  .technologies p {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    font-weight: bolder;
    font-weight: 400;
    color: white;
  }
  .soft-skills .hover-text {
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    font-weight: bold;
    font-weight: 400;
    color: #ffffff;
  }
  .technologies .icon-html {
    width: 2rem;
    height: 1.1rem;
    border-radius: 50%;
    background: white;
    padding: 0.1rem;
    color: #252525;
  }
  /* .soft-skills:hover ~ .technologies .brief_description_of_tech_section {
    width: 100%;
  } */
}
/* @media (width <= 720px) {
  .bottom-section-of-hero-section {
    position: absolute;
    bottom: -6vw;
    font-size: 1.5rem;
    width: 50vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 6rem;
    display: none;
  }
} */

@media (width <= 619px) {
  .soft-skills.active h1 {
    text-align: center;
    font-size: clamp(1.6rem, 3.5vw, 3.5vw); /* Default font size */
  }
  .soft-skills.active p {
    font-size: 0.75rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.5;
    width: 95%;
  }

  /* .bottom-section-of-hero-section {
    position: absolute;
    bottom: -6vw;
    font-size: 1.4rem;
    width: 55vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 5rem;
  } */

  /* .left-btn {
    left: -5vw;
  }
  .right-btn {
    left: 5vw;
  } */
}

/*Phone size for HERO PAGE -------------------------------------------------------------------------------------------*/
@media (max-width: 548.5px) {
  .get-to-know-me {
    position: absolute;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 0 3vw;
  }
  .soft-skill-item {
    padding: 4rem 1rem;
  }
  .why_me_txt {
    display: none;
    opacity: 0;
  }
  .top-section h2 {
    width: 70%;
  }

  .copyright_txt {
    font-size: 1rem;

    position: relative;
    left: 1.5rem;
    font-family: "Noto Sans", sans-serif;
  }
  /* .bottom-section-of-hero-section {
    position: absolute;
    bottom: 30vw;
    font-size: 1.4rem;
    width: 60vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    left: 1rem;
    display: none;
  } */
  .bottom-section-of-hero-section .about-me {
    padding: 0.7rem 0.8rem;
    color: white;
    background: #252525;
    font-size: 0.9rem;
    border-radius: 15px;
    outline: none;
    border: none;
    margin-left: 0.2vw;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: none;
  }
  .technologies .brief_description_of_tech_section {
    position: relative;
    top: 4rem;
    width: 50%;

    line-height: 1.5;
    font-weight: 200;
  }
  /* .get-to-know-me .item-title {
    width: 100%;
    height: 100%;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    color: #ffffff;
  } */
  /* .get-to-know-me .item-description {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 0.65rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    line-height: 1.3;
  } */

  .soft-skills_intro h6 {
    position: relative;
    top: 3rem;
    left: 1.5rem;
    width: fit-content;
    font-weight: 400;
    color: #f9f9f9;
  }

  .technologies .icon-html {
    width: 2rem;
    height: 1.1rem;
    border-radius: 50%;
    background: white;
    padding: 0.1rem;
    color: #252525;
  }

  .click-icon {
    display: block;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 9999;
    font-size: 1rem;
    color: white;
    opacity: 1;
  }

  /* .left-btn {
    position: relative;
    left: -3.1rem;
  }
  .right-btn {
    position: relative;
    left: 3rem;
  } */
}
@media (max-width: 560px) {
  .pick-project-section {
    width: 100%;
  }
  .pick-project-section > .btn {
    position: relative;
    border-radius: 15px;

    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    overflow: hidden;
    z-index: 0;
  }
}

.show-cases-small-screens {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  gap: 0rem;
  margin-bottom: 1rem;
}
.projects-small-screens {
  position: relative;
  width: 40%; /* Adjust as needed */
  height: 60vw; /* Adjust as needed */
  margin: 0 auto;
}

.projects-small-screens img {
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 70%; /* Adjust as needed */
  margin: 0 auto;
  border-radius: 15px;
  cursor: pointer;
  object-fit: cover;
  object-position: left;
}
.temporary-note {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: clamp(0.9vw, 0.6rem, 0.6rem);
  background: #171717;
  color: white;
  border-radius: 2em;
  padding: 0.7rem 1rem;
  font-family: "Noto Sans", sans-serif;
  z-index: 9999999;
}
.projects-small-screens h1 {
  font-family: "Lobster", sans-serif;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #ececec;
  font-size: 3vw;
}
.projects-small-screens p {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.5vw;
  color: #535353;
  margin-top: 0.5rem;
}

@media (max-width: 679px) {
  .show-cases-small-screens {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .projects-small-screens {
    position: relative;
    min-width: 70vw; /* Adjust as needed */
    height: 60vw; /* Adjust as needed */
    margin: 0 auto;
  }
  .projects-small-screens h1 {
    font-family: "Lobster", sans-serif;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid #ececec;
    font-size: 4vw;
  }
  .projects-small-screens p {
    font-family: "Noto Sans", sans-serif;
    font-size: 2vw;
    color: #535353;
    margin-top: 0.5rem;
  }

  .bottom-section-of-the-page-grid {
    width: 100%;
    height: 10rem;
    border-radius: 15px;
    position: relative;
    margin-bottom: 1rem;
  }
}
@media (max-width: 357px) {
}
@media (width > 1500px) {
  .top-section-corner p {
    font-family: "Noto Sans", sans-serif;
    font-size: 1.4rem;
  }
  .top-section-corner {
    width: 15rem;
  }
  .projects {
    margin-bottom: unset;
    top: unset;
    margin-bottom: 5rem;
    margin-top: 17rem;
  }
  .about-me_arrow {
    font-size: 1rem;
    left: 0.5rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: translateX(0%);
    border-radius: 50%;
  }
  .showcase-btn_arrow {
    font-size: 1rem;
    left: 0.5rem;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: translateX(0%);
    border-radius: 50%;
  }

  .about-me:hover .about-me_arrow {
    color: #171717;
    background: transparent;
    transition: all 0.3s ease-in-out;
    transform: translateX(100%) scale(2.2);
    background: white;
    border-radius: 50%;
  }
  .softSkills_btn {
    border-radius: 50%;
    background: transparent;
    border: 1px solid whitesmoke;
    padding: 0.7rem;
    height: 3.5rem;
    width: 3.5rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  mark {
    font-size: 0.7rem !important;
  }
  .top-section h1 {
    bottom: 3rem;
  }
  .about_author_section {
    top: 5rem;
  }
  .top-section {
    max-height: unset;
  }
  .wrapper-main {
    padding: 3rem 3.9rem;
  }
  .skills-section {
    top: 10rem;
  }

  .box-example {
    /* margin-top: 4vw; */
    max-height: unset;
    height: 100%;
  }
  .show-cases {
    width: 100%;
    height: clamp(600px, 80svh, 80svh);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .show-cases-menu-layout {
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 0;
  }
  .pick-project-section {
    grid-template-columns: 150px auto 150px;
  }
  .top-section h2 {
    bottom: 10rem;
  }
  .get-to-know-me {
    padding: 3rem 2rem;
  }
  .pick-project-section {
    height: clamp(100px, 10vh, 10vh);
  }
  .show-cases-menu-layout ul {
    margin-top: 4vw;
  }
  .about-me {
    padding: 1rem 1.5rem;
  }
}
/* @media (max-width: 464px) {
  .option_buttons ul {
    height: inherit;
    width: 100%;
    display: flex;
    border-radius: 15px;
    position: relative;
    background: rebeccapurple;
  }
  .option_buttons {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

  }

} */

@media (min-height: 947px) and (width < 1500px) {
  .wrapper-main {
    padding: 3rem 3.9rem;
  }
  .top-section h2 {
    font-size: 1rem;
  }
  .top-section_nav {
    font-size: 1.1rem !important;
  }
  .top-section .copyright_txt {
    font-size: 1.1rem !important;
  }
}
@media (min-height: 947px) and (max-width: 1143px) {
  .get-to-know-me .item-description {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: clamp(0.7rem, 1.2vw, 1vw);
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #252525;
    line-height: 1.6;
  }
}
@media (min-height: 947px) and (max-width: 847px) {
  .marquee-div {
    position: relative;
    width: 100%;
    height: auto;
    top: 55% !important;
  }
}
@media (min-height: 947px) and (max-width: 947px) {
  .wrapper-main {
    padding: 1.5rem 3.9rem;
  }
}

@media (min-height: 947px) and (width < 1500px) {
  .top-left-content {
    position: absolute;
    bottom: 9rem;
    height: 20rem;
    width: 52vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .top-section h1 {
    font-size: 5rem;
    bottom: 1.7rem;
  }
}
@media (orientation: portrait) and (width <= 624px) and (min-height: 947px) {
  .pick-project-section > div:nth-child(2) {
    width: auto;
    position: relative;
  }

  .pick-project-section {
    height: 5.5rem;
    display: grid;
    grid-template-columns: 1fr !important;
    justify-content: center;
    gap: 0rem;
    align-items: center;
    width: 90%;
  }

  .pick-project-section div:first-child {
    display: none;
  }
  .project-title {
    display: none;
  }
  .pick-project-section::after {
    position: absolute;
    content: "Works";
    width: 20%;
    height: 25%;
    font-size: 1.5rem;
    color: #171717;

    font-family: "Lobster", sans-serif;
    color: #171717;
    background: #fafafa;
    top: -12%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
  }
  .technologies.shrink .brief_description_of_tech_section {
    width: 70%;
  }
}

@media (orientation: portrait) and (min-height: 947px) {
  .top-section h1 {
    position: relative; /* Ensure the element can be positioned */
    bottom: 1rem;
  }
  .soft-skills.active h1 {
    font-size: clamp(1.6rem, 4vw, 4vw);
  }
  .soft-skills.active p {
    font-size: 0.8rem;
  }
  .wrapper-main {
    padding: 1.5rem 2rem;
  }
  .border-top-none {
    width: 100%;
    height: 3.7rem;
    display: grid;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }
  .about-author_content {
    background: unset;
  }
  .about_author_section {
    width: 100%;
    height: 22rem;

    border-radius: 15px;

    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
  }

  .about_author_section::after {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #171717ea;
  }
  .about_author_section::before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: unset;
    background-image: url("../../../public/assets/img/emoji.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(8px); /* Adjust the blur radius as needed */
  }

  .top-section {
    height: 95svh;
  }
  .get-to-know-me {
    position: absolute;
    right: 0;
    width: 100%;
    height: 22rem;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 0 3vw;
  }
  .why_me_txt {
    display: none;
    opacity: 0;
  }
  .get-to-know-me .item-title {
    width: 100%;
    height: 100%;
    position: relative;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-size: 0.9rem;
    color: white;
  }
  .get-to-know-me .item-description {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.7rem;

    color: white;
    line-height: 1.5;
  }
  .get-to-know-me .item {
    width: 100%;
    height: 4rem;
    border-top: unset;
    display: grid;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
  }
  .skills-section {
    width: 100%;
    height: 30rem;
    display: flex;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .layout-works {
    display: none;
  }
  .pick-project-section {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
}

/*FOR TABLET VIEW */

@media (min-height: 947px) and (max-width: 947px) {
  .marquee-div {
    top: 55%;
  }
  .top-section h1 {
    line-height: 1;
    font-weight: 700;
    color: #252525;
    font-family: "Lobster", sans-serif;
    position: relative;
    font-size: 10vw !important;
    bottom: 1.7rem;
  }
  .top-left-content {
    position: absolute;
    bottom: 10rem;
    height: 20rem;
    width: 120%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .top-section h2 {
    font-size: 1rem;
    width: 30vw;
  }
  .bottom-section-of-hero-section {
    position: relative;
    font-size: clamp(1rem, 1.5vw, 1.5vw);
    bottom: 0rem;
    width: clamp(20rem, 28vw, 28vw);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .about-me {
    padding: 0.7rem 1rem;
    color: white;
    background: #252525;
    font-size: 0.8rem;
    font-family: "Poppins", sans-serif;
    border-radius: 15px;
    outline: none;
    border: none;
    margin-left: 1vw;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
@media (min-height: 947px) and (max-width: 845px) {
  .marquee-div {
    top: 55%;
  }
  .top-section h1 {
    line-height: 1;
    font-weight: 700;
    color: #252525;
    font-family: "Lobster", sans-serif;
    position: relative;
    font-size: 8rem !important;
    bottom: 1.7rem;
  }
}

@media (hover: none) {
  .copyright_txt::before {
    all: unset;
  }
  .copyright_txt:hover::before {
    width: 0;
  }
  .top-section_nav a::before {
    all: unset;
  }
  .top-section_nav a:hover::before {
    width: 0;
  }
}
@media (hover: hover) {
  .copyright_txt::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 0.2vh;
    background-color: #0466c8;
    transition: width 0.3s ease;
  }
  .copyright_txt:hover::before {
    width: 100%;
  }
  .top-section_nav a::before {
    content: "";
    position: absolute;
    bottom: -3px;

    left: 0;
    width: 0;
    height: 0.2vh;
    background-color: #0466c8;
    transition: width 0.3s ease;
  }
  .top-section_nav a:hover::before {
    width: 100%;
  }
  .soft-skills:hover .negative-span h1 {
    position: relative;
    transform: translateX(0%);
    color: white;
    transition-delay: 0.3s; /* Added delay here */
  }

  .soft-skills:hover .positive-span h1 {
    transform: translateX(120%);
    color: white;
    transition-delay: 0.2s; /* Added delay here */
  }

  .soft-skills:hover .span-line-negative {
    transform: translateX(0%);
    transition-delay: 0.5s; /* Added delay here */
  }

  .soft-skills:hover .span-line-positive {
    transform: translateX(120%);
  }
  .technologies:hover > p:last-child span:last-child {
    transform: translateY(0%);
    transition: all 0.3s ease-in-out;
  }
  .technologies:hover > p:last-child span:first-child {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }
  .about_author_section:hover .about-author_arrow {
    transition: all 0.3s ease-in-out;
    transform: translateX(0%);
  }
}

@media (max-width: 418px) {
  .top-section h2 {
    font-size: clamp(
      0.9rem,
      4vw,
      4vw
    ); /* min 2rem, scales with viewport up to a max of 4vw */
    width: 90% !important;
  }
}
@media (max-width: 375px) {
  .wrapper-main {
    padding: 1rem 0.6rem;
  }
}

.animated-box {
  animation-duration: 0.5s;
  animation-name: animate-box;
}

@keyframes animate-box {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
