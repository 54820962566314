*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;

}
:root {
  --bg-intro-page: #fdfdfd;
  --color-one: #fafafa;
  --color-second: #171717;
}

body {
  text-rendering: optimizeSpeed;
  background: var(--color-one);
  overflow-x: hidden;
}

.html {
  scroll-behavior: smooth;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform-origin: bottom;
}

.black-box {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: #171717;
}
.black-box1 {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100vh;
  background-color: whitesmoke;
}
