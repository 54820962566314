@media (max-width: 478px) {
  .copyright-text h1 {
    font-size: 2.5vw !important;
  }
  .bottom-content-absolute-content {
    position: relative;
    left: 0 !important;
    display: flex;
    gap: 2rem;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
  }
  .icons-bottom {
    justify-content: center;
  }
  .icons-bottom .icon-contact {
    font-size:clamp( 0.7em, 3.5vw, 1.3rem );
  }
  .icons-bottom {
    gap: 0.7rem;
  }
}
@media (max-width: 364px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    gap: .5rem;
    margin-bottom: 1.5rem !important;
  }
  .copyright-text h1 {
    font-size: .7rem !important;
    border-right: none !important;
    
  }
  .contact-icons{
    justify-content: center !important; 
  }
}

.footer-main {
  position: fixed;
  width: 100%;
  height: 20vh; /* Changed from 20svh for better compatibility */
  bottom: 0;
  background: #171717;
  z-index: -1; /* Adjusted z-index for visibility */
}

.icon-contact {
  position: relative;
  font-size: clamp(1em, 1.3vw, 1.3rem);
  background: transparent;
  border-radius: 50%;
  color: white;
  display: flex;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.contact-section,
.contact-section-form {
  margin-left: 3.9vw;
  position: relative;
  font-family: "Noto Sans", sans-serif;
}

.contact-icons {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.contact-icons p {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-content: center;
  width: 100%;
  font-size: clamp(0.6em, 0.8vw, 0.8vw);
  left: 0;
  top: 0.4vw;
  color: white;
}

.copyright-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-text h1 {
  font-size: clamp(0.7em, 0.8vw, 0.9rem);
  font-weight: 400;
  position: relative;
  color: white;
  border-right: 1px solid white;
  padding: 0.2rem 1rem;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.6;
}

.bottom-section-of-the-page-email-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
  height: 87.4%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
}

.contact-btn {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  color: white;
  font-size: 1vw;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.bottom-content-absolute-content {
  position: relative;
  left: 2rem;
  display: flex;
  gap: 2rem;
  height: 100%;
  align-items: flex-end;
}

.copyright-text,
.contact-section,
.contact-section-form {
  font-family: "Noto Sans", sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  white-space: nowrap;
  width: max-content;
}

.icons-bottom {
  position: relative;
  display: flex;
  gap: 0.8rem;
  padding: 0.2rem 1rem;
}

.footer-content {
  display: flex;
  margin-bottom: 2rem;
}

@media (hover: hover) {
  .icons-bottom .icon-contact:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
    color: #0466c8;
    cursor: pointer;
  }
}

@media (hover: none) {
  .icons-bottom .icon-contact:hover {
    color: unset;
  }
}
