.about-content-wrapper {
  position: relative;

  background: var(--color-one);
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.about-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem 3.9rem;
  background: var(--color-one);
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}

.about-content .box {
  position: relative;
  width: 100%; /* Responsive width */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.about-content h1 {
  position: absolute;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  color: #171717;

  gap: 0.3rem;
  font-size: 0.8rem;
  top: 2rem;
  left: 0vw;
  font-weight: 400;
}
.navLink p {
  all: unset;
  position: relative;
  top: 2rem;
  width: min-content;
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  font-family: "Noto Sans", sans-serif;
  color: #171717;
  align-items: center;
  gap: 0.3rem;
  font-size: clamp(0.9rem, 1.2vw, 1.4rem);

  white-space: nowrap;
}

.box .column {
  position: relative;
  width: 100%;
}
.first-row_text .about-me-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  font-family: "Noto Sans", sans-serif;
  line-height: 2;
  gap: 1rem;
  font-size: 0.85rem;
  border-radius: 1.2em;
  padding-left: 1rem;
  position: relative;
}
.first-row_text {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 70svh;
  gap: 2rem;
}
.about-me-text .download-link {
  background: transparent;
  font-family: "Lobster", sans-serif;
  color: #171717;
  border-radius: 1em;
  font-weight: 200;
  padding: 0.5rem 0rem;
  position: relative;
  font-size: 0.8rem;
  display: flex;
  justify-content: first baseline;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
}

.about-me-text .download-link::after {
  content: "";
  position: absolute;
  bottom: 7px; /* Adjust this value to position the underline lower */
  left: 50%;
  width: 100%;
  height: 0.2vh;
  background-color: #0466c8; /* Set the color of the underline */
  transform: translateX(-50%); /* Center the underline */
}

.about-me-photo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.2rem;
  overflow: hidden;
}
.about-me-photo .photo {
  position: relative;
  background: url("../../../public/assets/img/me.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 110%;
  border-radius: 1.2rem;
  transition: all 0.1s ease-in-out;
}
.about-content .copyright_txt {
  position: absolute;
  display: flex;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
  color: #171717;

  align-items: center;
  gap: 0.3rem;
  font-size: clamp(0.9rem, 1.2vw, 1.4rem);
  top: 2rem;
  transition: all 0.3s ease-in-out;
  width: max-content;
  white-space: nowrap;
}

/* .about-me-photo::after{
    position: absolute;
    bottom: -5rem;
    right: -7rem;
    content: "About me ...";
    width: 20rem;
    height: 10rem;
    font-family: "Lobster", sans-serif;
    font-size: 4rem;
    color: #0466c8;
  
  } */
.about-me-text p {
  width: 80%;
  font-family: "Noto Sans", sans-serif;
}
.about-me-text p span {
  font-family: "Lobster", sans-serif;
  font-size: 1.5rem;
}
.certificates {
  position: relative;
}
.certificates ul {
  position: relative;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  top: 1rem;
}

.second-row_text h1 span {
  width: 100%;
  height: 1px;
  background: rgb(206, 206, 206);
  /* display: flex;
  justify-self: center;
  align-self: center;
  position: relative;
  justify-content: center;
  align-items: center; */
  top: unset;
}
.certificates ul li {
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 1.2rem;
  /* border: 1px solid rgba(234, 234, 234, 0.929); */
  width: fit-content;
  font-size: clamp(0.7rem, 0.9vw, 0.9vw);
  line-height: 1.3;
  margin-top: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 8rem;
  font-family: "Poppins", sans-serif;
  color: #171717;
  z-index: 0; /* Ensure content is above the pseudo-element */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.certificates ul li span {
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  color: #171717;
  font-size: 0.7rem;
  font-family: "Lobster", sans-serif;
}
.certificate-icon {
  position: absolute;
  top: -1rem;
  right: -1rem;
  background: #0466c8;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 2.5rem;
}
.second-row-certificates {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  margin-top: 3rem;
}
.second-row_text {
  position: relative;
  top: 0;
}

.second-row_text h1 {
  position: relative;
  font-size: 2rem;
  font-family: "Noto Sans", sans-serif;
  top: 0;
}
.certificates {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 1rem;
}

.third-row {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 1.2rem;
  margin-top: 4rem;
  gap: none;
}

.third-row_text h1 {
  position: relative;
  font-size: 2rem;
  font-family: "Noto Sans", sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; /* Ensures the text remains on one line */
}
.third-row_content {
  height: 15rem;
  border-radius: 1.2rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: none;
  border: 1px solid #171717;
  margin-top: 2.6rem;
}
.rta::after {
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  content: "01";
  font-size: 4rem;
  color: #171717;
  font-family: "Lobster", sans-serif;
}

.garkalni::after {
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  content: "02";
  font-size: 4rem;
  color: #171717;
  font-family: "Lobster", sans-serif;
}
.third-row_left-content h4 {
  color: #171717;
  border: 1px solid #171717;
}
.third-row_left-content h4 a {
  color: #171717;
}
.third-row_left-content h7 {
  color: #171717;
}
.third-row_right-content h3 {
  color: #171717;
}
.third-row_right-content .work-link {
  color: #171717;
  font-weight: 700;
}
.about-me-text p a {
  color: #171717;
  text-decoration: 1.5px #0466c8 underline;
}
.shaped-box {
  background: var(--color-one);
  width: 2rem;
  height: 2rem;
  position: absolute;
  bottom: -1rem;
  transform: rotate(45deg);
  border-left: 1px solid #171717;
  border-bottom: 1px solid #171717;

  left: calc(50% - 1rem); /* Center the box horizontally */
}
.shaped-box.garkalni-shape {
  background: var(--color-one);
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: -1rem;
  transform: rotate(45deg);
  left: calc(50% - 1rem); /* Center the box horizontally */
  border-top: 1px solid #171717;
  border-right: 1px solid #171717;
  border-bottom: unset;
  border-left: unset;
}

.arrow-right {
  border: 1px solid #171717;
  position: absolute;
  color: #171717;
  top: calc(50% - 1rem);
  border-radius: 50%;
  left: calc(50% - 1rem);
  padding: 0.2rem;
  font-size: 2rem;
}

.third-row_left-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  gap: 2rem;
  margin: 0 auto;
}

.third-row_left-content h7 {
  position: relative;

  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  line-height: 1.5;
}

.third-row_content h4 {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 1.2em;
  width: fit-content;
  color: #171717;
  padding: 0.2rem 1rem;
}

.third-row_right-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.third-row_right-content h3 {
  position: relative;
  font-size: 0.8rem;
  width: 85%;
  font-family: "Poppins", sans-serif;
  left: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
h4 a {
  text-decoration: none;
  color: #0466c8;
}
@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media (width > 1500px) {
  .about-content {
    padding: 2rem 5rem;
  }

  .about-content .copyright_txt {
    color: #171717;
    font-family: "Noto Sans", sans-serif;
    top: 1.8rem !important;
  }
  .about-me-photo::after {
    font-size: 1.5rem;
    border-radius: 1.2rem;
    height: 10rem;
  }
  .certificates ul li {
    font-size: 1rem !important;
  }
  .about-me-text .download-link {
    font-size: 1.2rem;
  }
  .first-row_text .about-me-text {
    font-size: clamp(1rem, 1vw, 1vw);
  }
  .first-row_text .about-me-text p {
    width: 81%;
    line-height: 2;
  }
  .about-me-text p span {
    font-family: "Lobster", sans-serif;
    font-size: 2rem;
  }
  .certificates ul li {
    font-size: 0.85rem;
    height: 11rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  .certificates ul li span {
    font-size: 0.8rem;
  }
  .certificate-icon {
    font-size: 2.7rem;
  }
  .third-row_content h4 {
    font-size: 1.2rem;
  }
  .third-row_left-content h7 {
    font-size: 1rem;
  }
  .third-row_left-content p {
    font-size: 0.8rem;
  }
  .third-row_right-content h3 {
    font-size: 1rem;
  }
  .arrow-right {
    font-size: 2.5rem;
  }
  .third-row_content {
    height: 17rem;
  }
  .about-me-text h4 {
    font-size: clamp(0.8rem, 0.9vw, 0.9vw);
  }
  .certificate-icon {
    padding: 0.7rem;

    font-size: 3.5rem !important;
  }
}

@media (max-width: 796px) {
  .first-row_text .about-me-text {
    font-size: clamp(1rem, 1.7vw, 1.7vw);
  }
  .work-link {
    width: 100% !important;
    left: 0 !important;
  }
}
@media (max-width: 951px) {
  .first-row_text {
    position: relative;
    display: unset;
    grid-template-columns: unset;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    gap: 2rem;
  }
  .about-me-photo .photo {
    height: 140%;
    border-radius: 1.2rem;
  }
  .about-me-photo .photo::after {
    bottom: 5rem;
    border-radius: 1.2rem;
  }
  .first-row_text .about-me-text {
    padding: 1rem 1rem;
  }
  .about-me-text p {
    width: 100%;
    font-size: 0.8rem;
  }
  .first-row_text .about-me-text {
    font-size: clamp(0.7rem, 1.3vw, 1.3vw);
  }
}
@media (max-width: 706px) {
  .about-content {
    padding: 1rem 1.5rem;
  }
  .navLink p {
    all: unset;
    position: relative;
    top: 2.5rem;
    width: min-content;

    display: flex;
    flex-direction: row;
    justify-self: center;
    align-self: center;
    font-family: "Noto Sans", sans-serif;
    color: #171717;
    align-items: center;
    gap: 0.3rem;

    white-space: nowrap;
  }
  .timeline-wrapper h1 {
    font-size: clamp(1.7rem, 1.8vw, 1.8vw) !important;
  }
  .second-row_text h1 {
    font-size: clamp(1.7rem, 1.8vw, 1.8vw) !important;
  }
  .third-row_text h1 {
    font-size: clamp(1.7rem, 1.8vw, 1.8vw) !important;
  }
}
@media (max-width: 862px) {
  .certificates ul li {
    width: 45%;
    height: 8rem;
  }
}

@media (max-width: 930px) {
  .third-row_content h4 {
    font-size: clamp(0.9rem, 1.1vw, 1.1vw);
  }
  .third-row_left-content h7 {
    font-size: clamp(0.73rem, 1.2vw, 1.2vw);
  }
  .third-row_left-content p {
    font-size: 0.8rem;
  }
  .third-row_right-content h3 {
    font-size: clamp(0.73rem, 1.2vw, 1.2vw);
  }
}
@media (max-width: 833px) {
  .first-row_text .about-me-text {
    position: relative;
    padding-bottom: 1rem;
  }
}
@media (max-width: 796px) {
  .third-row_content {
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 1.2rem;
    position: relative;
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    background-size: auto;
    background-size: auto;
    animation: gradient 10s ease infinite;
    background-size: 200% 200%;
    padding: 2.5rem 0;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .third-row_right-content {
    position: relative;
    display: flex;
    justify-content: first baseline;
    align-items: first baseline;
    width: 85%;
    margin: 0 auto;
  }

  .third-row_right-content h3 {
    left: unset;
    width: 100%;
  }

  .arrow-right {
    position: absolute;
    padding: 0.2rem;
    font-size: 2rem;
    top: 2rem;
    border-radius: 50%;
    left: unset;
    transform: rotate(120deg);
    right: 2rem;
  }
  .third-row {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 1.2rem;
    margin-top: 3rem;
    padding-bottom: 1rem;
  }
  .about-content {
    margin-bottom: 5rem;
  }
  .about-me-photo::after {
    height: 9rem;
  }
}

mark {
  font-size: 0.6rem;
  position: absolute;
  background: transparent;
  border: 1px solid #dedede;
  border-radius: 1em;
  padding: 0.2rem 1rem;
  bottom: 0.5rem;
  left: 1rem;
}

@media (min-height: 947px) and (max-width: 951px) {
  .first-row_text .about-me-text p {
    font-size: 0.85rem !important;
  }
  .about-me-text p span {
    font-size: 1.9rem !important;
  }
  .about-me-text .download-link {
    font-size: 0.95rem !important;
  }
  .about-me-photo::after {
    font-size: 2rem !important;
    padding: 0rem 2rem !important;

    height: 10vh;
  }
}

.column h1 span {
  background: #d2d2d2ec !important;
}
.column h1 {
  gap: 2rem;
}

.third-row_right-content {
  display: flex;
  flex-direction: column;
}
.work-link {
  position: relative;
  font-size: 0.7rem;

  width: 85%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  color: #171717;
  left: 1rem;
  top: 1rem;
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 0.2rem;
}
.arrow-right-project {
  font-size: 1rem;
}
