.expanded_tech_grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 0.4fr 1fr;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* overflow: hidden; */
}
.knowledge_pointer {
  position: absolute;
  bottom: 0.7rem;
  /* margin-inline: auto;
  justify-self: center; */
  right: 0.5rem;
  border-radius: 1vw;
  padding: 0.2rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #dedede;
  gap: 1rem;
  /* border: .1px solid rgb(98, 98, 98); */
  font-size: clamp(0.6rem, 0.8vw, 1rem);
  font-family: "Noto Sans", sans-serif;
  overflow: visible;
}





.option_buttons ul {
  height: 60%;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 15px;
}
.custom-box{
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: .8rem;
  font-family: "Noto Sans", sans-serif;

}
.option_buttons {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.option_buttons button {
  background: none;
  border: none;
  gap: 1.2rem;
  z-index: 9999;
  padding: 0.2rem 0rem;
  font-size: clamp(0.6rem, 1vw, 1.3rem);
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
  position: relative;
}

.option_buttons button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #0466c8;
  transition: width 0.3s ease;
}

.option_content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.option_buttons button.active::before {
  width: 100%;
}

.carousel-content {
  position: relative;
  /* margin-bottom: 3rem; */
}
.option_content .box i {
  position: relative;
  background: #252525;

  font-size: 4vw;
  color: white;
  display: flex;
  justify-self: center;
  align-self: center;
  padding: 1.75rem;
  border-radius: 15px;
  transition: all 0.4s ease-in-out;
  margin-bottom: 3rem;
}
@media (max-width: 492px) {
  .softSkills_btn {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.option_content .box i:hover {
  position: relative;
  background: #0466c8;
  color: white;
  transition: all 0.4s ease-in-out;
  cursor: initial;
}
.option_content .box {
  position: relative;
  width: 12vw;
  height: 10.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}
.option_content .box i::after {
  position: absolute;
  bottom: 0.7vw;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  content: attr(data-description);
  width: 100%;
  height: 1.4vh;
  font-size: 0.85vw;
  padding: 0;
}
.option_content .box i::before {
  position: absolute;
  bottom: -0.3vw;
  right: -0.4vw;
  display: flex;
  border: 2px solid #171717;
  flex-direction: column;
  align-items: center;
  content: " ";
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 50%;
  font-size: 1vw;
  padding: 0;
}

.option_content .box .status-color.relevant::before {
  background: #0466c8;
}

.option_content .box .status-color.notrelevant::before {
  background: #e8e8e8;
}

.knowledge_pointer .relevant-color,
.have-experience-color {
  position: relative;
  padding: 0.3rem 1rem;
}
/* .have-experience-color {
  border-left: 1px solid rgb(194, 194, 194);
} */
.knowledge_pointer .relevant-color::after {
  position: absolute;
  width: 0.35vw;
  height: 0.35vw;
  content: " ";
  background: #0466c8;
  border-radius: 50%;
  right: -0.3vw;
  top: 40%;
}
.knowledge_pointer .have-experience-color::after {
  position: absolute;
  width: 0.35vw;
  height: 0.35vw;
  content: " ";
  background: #e8e8e8;
  border-radius: 50%;
  right: -0.3vw;
  top: 40%;
}
.category_btns{
  color: gray;
}
.changed-option_button .category_btns {
  color: white;
  background: rgb(32, 32, 32);
}

.changed-option_button .category_btns a{
  color: white !important;
}


.changed-option_button ul a{
  color: gray;
  text-decoration: none;
  background: none;
  border: none;
  gap: 1.2rem;
  z-index: 9999;
  padding: 0.2rem 0rem;
  font-size: clamp(0.8rem, 1.5vw, 1.3rem);
  cursor: pointer;
  font-family: "Noto Sans", sans-serif;
  position: relative;

}
.category_btns.active {
  color: white;
}
.box {
  border-radius: 15px;
}

@media (max-width: 1006px) {
  .expanded_tech_grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 5vw 1fr;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
  }
}

@media (max-width: 1096px) {
  .expanded_tech_grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 4.5rem 1fr;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
  }

  /* .knowledge_pointer {
    width: 40%;
    position: absolute;
    top: 5.5vw;
    gap: 0.1rem;

    padding: 0.4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #5e5e5e;
    font-size: 0.98vw;
  } */
  .option_content .box i {
    position: relative;
    background: #252525;

    font-size: 4rem;
    color: white;
    display: flex;
    justify-self: center;
    align-self: center;
    padding: 1.75rem;
    transition: all 0.4s ease-in-out;
  }
  .option_content .box {
    position: relative;
    width: 10rem;
    height: 9rem;
  }
  .option_buttons ul {
    height: 60%;
    width: fit-content;
    display: flex;
    justify-content: center;
    gap: 0 2rem;
    padding: 0 2rem;
    align-items: center;
  }
  /* .knowledge_pointer {
    width: 40%;
    position: absolute;
    top: 5.5rem;

    border-radius: 1vw;
    padding: 0.4vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #dedede;
    gap: 1.6rem;
    font-size: 0.75rem;
  } */
  .knowledge_pointer .relevant-color,
  .have-experience-color {
    position: relative;
    padding: 0.3rem 1rem;
  }
  .have-experience-color {
    border-left: 1px solid rgb(194, 194, 194);
  }
  .knowledge_pointer .relevant-color::after {
    position: absolute;
    width: 0.3rem;
    height: 0.3rem;
    content: " ";
    background: #0466c8;
    border-radius: 50%;
    right: -0.3rem;
    top: 40%;
  }
  .knowledge_pointer .have-experience-color::after {
    position: absolute;
    width: 0.3rem;
    height: 0.3rem;
    content: " ";
    border-radius: 50%;
    right: -0.3rem;
    top: 40%;
  }
  .option_content .box i::after {
    position: absolute;
    bottom: 0.7rem;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    content: attr(data-description);
    width: 100%;
    height: 1.4vh;
    font-size: 0.7rem;
    padding: 0;
  }
  .option_content .box i::before {
    position: absolute;
    bottom: -0.1rem;
    right: -0.2rem;
    display: flex;
    border: 2px solid #171717;
    flex-direction: column;
    align-items: center;
    content: " ";
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    font-size: 1rem;
    padding: 0;
  }
}

@media (max-width: 802px) {
  .expanded_tech_grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 4.5rem 1fr;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
  }
  /* .option_buttons button {
    background: none;
    border: none;
    border-radius: 15px;
    gap: 1.2rem;
    z-index: 9999;
    padding: 1rem;
    font-size: 0.7rem;
    cursor: pointer;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  } */
  .option_content .box i {
    position: relative;
    background: #252525;

    font-size: 4rem;
    color: white;
    display: flex;
    justify-self: center;
    align-self: center;
    padding: 1.75rem;
    border-radius: 15px;
    transition: all 0.4s ease-in-out;
  }

  .option_buttons ul {
    height: 60%;
    width: 70%;
  }

  /* .knowledge_pointer {
    width: 40%;
    position: absolute;
    top: 5.5rem;

    border-radius: 1vw;
    padding: 0.4vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #dedede;
    gap: 1.6rem;
    font-size: 0.75rem;
  } */
  .knowledge_pointer .relevant-color,
  .have-experience-color {
    position: relative;
    padding: 0.3rem 1rem;
  }
  .have-experience-color {
    border-left: 1px solid rgb(194, 194, 194);
  }
  .knowledge_pointer .relevant-color::after {
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    content: " ";
    border-radius: 50%;
    right: -0.3vw;
    top: 40%;
  }
  .knowledge_pointer .have-experience-color::after {
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    content: " ";
    border-radius: 50%;
    right: -0.3vw;
    top: 40%;
  }
}

@media (max-width: 859px) {
  .expanded_tech_grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 4.5rem 1fr;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
  }
  /* .option_buttons button {
    background: none;
    border: none;
    gap: 1.2rem;
    z-index: 9999;
    padding: 1rem;
    font-size: 0.7rem;
    width: auto;
    cursor: pointer;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  } */
}
@media (max-width: 740px) {
  .option_buttons ul {
    height: 60%;
    width: 90%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 15px;
  }
}
@media (width <= 622px) {
  /* .knowledge_pointer {
    width: 70%;
    position: absolute;
    top: 5.5rem;

    border-radius: 1vw;
    padding: 0.4vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #dedede;
    gap: 1.6rem;
    font-size: 0.7rem;
  } */
  .knowledge_pointer .relevant-color::after {
    position: absolute;
    width: 0.35rem;
    height: 0.35rem;
    content: " ";
    background: #0466c8;
    border-radius: 50%;
    right: -0.3rem;
    top: 40%;
  }
  .knowledge_pointer .have-experience-color::after {
    position: absolute;
    width: 0.35rem;
    height: 0.35rem;
    content: " ";
    border-radius: 50%;
    right: -0.3vw;
    top: 40%;
  }
}
@media (max-width: 601px) {
  .option_buttons ul {
    height: 60%;
    width: 95%;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 15px;
  }
}

@media (max-width: 563px) {
  .option_buttons ul {
    height: inherit;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 2rem;
    border-radius: 15px;
    flex-wrap: wrap;
    padding: 0.2rem 1rem;
    margin-top: 1rem;
  }

  /* .option_buttons button {
    background: none;
    border: none;
    gap: 1.2rem;
    z-index: 9999;
    padding: 1rem;
    font-size: 0.6rem;
    width: auto;
    cursor: pointer;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  } */
  .option_content .box i {
    position: relative;
    background: #252525;
    font-size: 3rem;
    color: white;
    display: flex;
    justify-self: center;
    align-self: center;
    padding: 1.75rem;
    border-radius: 15px;
    transition: all 0.4s ease-in-out;
  }
  .option_content .box {
    position: relative;
    width: 8rem;
    height: 9rem;
  }
}
/* @media (max-width: 563px) {
  .option_buttons ul {
    height: inherit;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 2rem;
    border-radius: 15px;
    flex-wrap: wrap;
    padding: 0.2rem 1rem;
    margin-top: 1rem;
    width: 100% !important;
  }
} */
@media (width > 1500px) {
  .option_content .box {
    position: relative;
    width: 12rem;
    height: 9rem;
  }
  .option_content .box i {
    font-size: 6rem;
  }

}

