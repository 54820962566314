.intro {
  position: relative;
  width: 100%;
  height: 100svh;
  background: var(--color-second);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-one);
  font-family: "Lobster", sans-serif;
  font-size: 20px;
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
  transition: all 0.3s ease-in;
}
.opacity-1 {
  opacity: 1;
  transition: all 0.3s ease-in;
}

.intro h2 {
  position: absolute;
  bottom: 1rem;
  margin: 0 auto;
  color: rgb(145, 145, 145);
  font-size: 0.8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
