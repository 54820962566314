.timeline-wrapper {
  position: relative;
  margin-top: 3rem;
  background: #171717;
  border-radius: 1.5rem;
  padding: unset;
}
.timeline-wrapper-corner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5vh;
  background: var(--color-one);
  top: -0.4rem;
  right: -0.1rem;
  border-bottom-left-radius: 1em;

  padding-top: 0.5rem;
}

.timeline-wrapper-corner::after {
  position: absolute;
  bottom: -0.8rem;
  right: -0.95rem;
  content: " ";
  width: 30px;
  height: 30px;
  --border-radius: 50%; /* Increase border-radius for a bigger circle */
  --corner-size: 0.6; /* Adjust corner size accordingly */
  --color-light: var(--color-one); /* Example value for light color */

  background-image: radial-gradient(
    circle at 100% 100%,
    transparent calc(var(--border-radius) * var(--corner-size)),
    var(--color-light) calc((var(--border-radius) * var(--corner-size)) + 0.5px)
  );
  transform: rotate(90deg); /* Adjust rotation and positioning */
}
.timeline-wrapper-corner::before {
  position: absolute;
  top: -0.65rem;
  left: -0.82rem;
  content: " ";
  width: 30px;
  height: 30px;
  --border-radius: 50%; /* Increase border-radius for a bigger circle */
  --corner-size: 0.6; /* Adjust corner size accordingly */
  --color-light: var(--color-one); /* Example value for light color */

  background-image: radial-gradient(
    circle at 100% 100%,
    transparent calc(var(--border-radius) * var(--corner-size)),
    var(--color-light) calc((var(--border-radius) * var(--corner-size)) + 0.5px)
  );
  transform: rotate(90deg); /* Adjust rotation and positioning */
}

.timeline-wrapper ul {
  margin-top: 2rem;
}
.timeline-wrapper h1 {
  position: relative;
  font-size: 2rem;
  font-family: "Noto Sans", sans-serif;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-row_text h1 {
  display: flex;
  justify-content: center;
  align-items: center;
}


.timeline-wrapper h1 span {
  width: 100%;
  height: 1px;
  background: rgba(234, 234, 234);
  top: unset;
}
.second-row_text h1 span {
  width: 100%;
  height: 1px;
  background: rgba(234, 234, 234);
  top: unset;
}
.third-row_text h1 span {
  width: 100%;
  height: 1px;
  background: rgba(234, 234, 234);
  top: unset;
}

.third-row_text h1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline-icon {
  font-size: 1.5rem !important;
}
.timeline-span {
  font-size: 0.9rem !important;
  font-family: "Poppins" sans-serif;
  font-weight: 400 !important;
}
.timeline-opposite-content {
  font-size: 0.75rem !important;
}
.timeline-typography {
  font-size: 0.65rem !important;
}

@media (width > 1500px) {
  .timeline-icon {
    font-size: 2rem !important;
  }
  .timeline-span {
    font-size: 1.2rem !important;
    font-family: "Poppins" sans-serif;
    font-weight: 400 !important;
  }
  .timeline-opposite-content {
    font-size: 0.8rem !important;
  }
  .timeline-typography {
    font-size: 0.8rem !important;
  }

  .box-items .box p {
    font-family: "Lobster", sans-serif;
    font-size: 2rem !important;
    color: white;
  }
  .box-items .box h7 {
    font-family: "Noto Sans", sans-serif;
    font-size: 0.9rem !important;
    color: whitesmoke;
  }
  .box-icon {
    font-size: 2rem;
  }
  .box-items .box h4 {
    position: absolute;
    bottom: 0em;
    right: 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 0.9rem !important;
    font-weight: 400 !important;
    color: white;
  }

  .timeline-wrapper h1 {
    font-size: 1.5rem !important;
    margin-bottom: 0.5rem;
  }
  .box-icon {
    font-size: 2.5rem !important;
    color: white;
  }
}
/* .box-items .box.hidden {
  transform: translateY(100px);
  transition: all 0.5s ease-in-out;

}

.box-items .box.visible {
  transform: translateY(0px);
  transition: all 0.5s ease-in-out;

} */
.box-items {
  margin: 0 auto;
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0 2rem;
  padding: 0 3rem;
  justify-content: space-between;
}
.box-items .box {
  padding: unset;
  margin: unset;
  border-radius: unset;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 30vh;
  gap: 1rem;
  align-items: first baseline;
  transition: all 0.5s ease-in-out;
}
.animated-box {
  animation-duration: 0.5s;
  animation-name: animate-box;
}

@keyframes animate-box {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.box-items .box p {
  font-family: "Lobster", sans-serif;
  font-size: clamp(19px, 1vw, 24px);
  color: white;
}
.box-items .box h7 {
  font-family: "Noto Sans", sans-serif;
  font-size: 0.65rem;
  color: whitesmoke;
}
.box-icon {
  font-size: 2rem;
  color: white;
}
.box-items .box h4 {
  position: absolute;
  bottom: 0em;
  right: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.6rem;
  font-weight: 400 !important;
  color: white;
}

.timeline-wrapper h1 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 782px) {
  .timeline-wrapper .box-items {
    display: flex;
    flex-direction: column !important;
    gap: 2rem !important;

    padding: 2rem 2rem;
  }
  .box-items .box {
    width: 100%;
    max-height: 20vh;
  }
  .timeline-wrapper-corner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: 5vh;
    background: var(--color-one);
    top: -0.4rem;
    right: -0.1rem;
    border-bottom-left-radius: 1em;

    padding-top: 0.5rem;
  }
  .timeline-wrapper h1 {
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
  }
}
