strong {
  color: #171717;
}
.work-video-restricted p {
  z-index: 13;
  color: white;
  font-size: clamp(0.88rem, 2.6vw, 1.3rem);
  font-family: "lobster", sans-serif;
}

.video-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgb(255, 255, 255);
  left: 0;
  top: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
.project-name {
  position: absolute;
  top: 2rem;
  right: 3rem;
  z-index: 3;
  color: white;
  font-family: "lobster", sans-serif;
}

.video-container .video-box {
  width: 80%;
  height: auto;
  position: absolute;
  display: flex;
  justify-self: center;
  align-self: center;
}
.video-container .video-box video {
  all: unset;
  position: relative;
  width: 100%;
  height: auto;

  object-fit: contain;

  border-radius: 15px;
  z-index: 2;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.video-container button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: #ececec;
  z-index: 9999999;
  overflow: hidden;
  cursor: pointer;
  background: #171717;
  border-radius: 50%;
  border: 2px solid #171717;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 5rem;
  height: 5rem;
}
.close-video-btn {
  z-index: 4;
  font-size: 2rem;
}
.video-container button:hover .close-video-btn {
  color: #ececec;
}
.video-container button::before {
  position: absolute;
  width: 1px;
  height: 1px;
  content: "";
  background: #0466c8;
  display: flex;
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  transition: width 0.3s, height 0.3s; /* Adding transitions for smooth animation */
}

.video-container button:hover::before {
  width: 100%;
  height: 100%;
}

.work-video button::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  background: #0466c8;
  display: flex;
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  transition: width 0.3s, height 0.3s; /* Adding transitions for smooth animation */
}
.work-video button:hover .play-btn {
  color: white;
  transition: all 0.3s ease-in-out;
}

.work-video button:hover::before {
  width: 100px;
  height: 100px;
}
.work-video button {
  background: #ececec;
  color: #171717;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2.5px solid #171717;
  width: 5rem;
  height: 5rem;
  z-index: 2;
  overflow: hidden;
  cursor: pointer;
}
.works-wrapper {
  position: relative;

  background: var(--color-one);
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}
.works-section-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2rem 3.9rem;
  background: var(--color-one);
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}

.play-btn {
  font-size: 1.5rem;
  overflow: hidden;
  z-index: 4;
  transition: all 0.3s ease-in-out;
}

.works .copyright_txt {
  text-decoration: none;
  width: fit-content;
  position: relative;
  left: 0;
  z-index: 4;
}
.works a {
  position: relative;
}

.works-section-page .works {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 0.01fr 0.4fr 0.5fr auto auto auto;
  border-radius: 15px;
  grid-gap: 2rem;
  padding: 0;
  margin: 0;
}
.next_case_icon {
  width: 100%;
}

.icon:hover {
  color: #0466c8;
  transform: scale3d(1.5, 1.5, 1);
  transform-style: preserve-3d;
}
/* .works-section-page .footer-main {
  width: 100%;
  height: 25vh;
  position: relative;
  top: 8rem;
} */
.tools-section {
  position: relative;
  font-size: 1vw;
  border: 1px solid rgb(206, 206, 206);
  padding: 2vw 5vw;
  color: rgb(119, 119, 119);
  border-radius: 15px;
  line-height: 1.5;
  height: fit-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0rem 2rem;
}
.tool {
  color: #171717;
  font-size: 2vw;
  /* margin-left: 2rem; */
  font-size: 1.6vw;
}

.work_name {
  position: relative;
  color: #171717;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}
.work_name h1 {
  font-family: "lobster", sans-serif;
  font-size: 6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  width: fit-content;
  padding: 0;
  margin: 0;
}

.line {
  background: rgb(206, 206, 206);
  position: relative;
  width: 80%;
  height: 1px;
  top: 1vw;
}
.decor {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-left: 2rem;
  width: 40%;
}
.video_buble {
  position: relative;
  right: 0;
  width: 13rem;
  height: 10rem;

  background: #0466c8;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  z-index: 999;
}
.work_description {
  font-family: "Noto Sans", sans-serif;
  width: 100%;
  position: relative;
  height: auto;
  display: grid;
  grid-template-rows: auto auto;
  color: #171717;
  gap: 2rem 0;
  top: 1rem;
}
.first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  gap: 0 2rem;
}
.second-row {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: clamp(0.8rem, 1vw, 1vw);
  border: 1px solid rgb(206, 206, 206);
  padding: 2vw 4vw;
  color: #171717;
  border-radius: 15px;
  line-height: 1.5;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description::after {
  position: absolute;
  content: "About";
  font-weight: 600;
  background: var(--color-one);
  width: auto;
  height: auto;
  left: 2vw;
  top: -0.8vw;
  font-size: 1.1vw;
  color: rgb(41, 41, 41);

  padding: 0 0.5rem;
}
.tools-section::after {
  position: absolute;
  content: "Tools";
  font-weight: 600;
  background: var(--color-one);
  width: auto;
  height: auto;
  left: 2vw;
  top: -0.8vw;
  font-size: 1.1vw;
  color: rgb(41, 41, 41);

  padding: 0 0.5rem;
}
.addition::after {
  position: absolute;
  content: "Side Note";
  background: var(--color-one);
  width: auto;
  height: auto;
  font-weight: 600;
  left: 2vw;
  top: -0.8vw;
  color: #171717;
  font-size: 1.1vw;
  padding: 0 0.5rem;
}
.addition {
  position: relative;
  font-size: 1vw;
  border: 1px solid rgb(206, 206, 206);
  padding: 2vw 5vw;
  color: #171717;
  border-radius: 15px;
  line-height: 1.5;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}
.next-work {
  width: 16rem;
  height: 15vw;
  border-radius: 15px;
  background-position: 100% 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100%;
  position: relative;
  top: 4vw;
  transition: all 0.3s ease-in-out;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.work_image,
.work-video {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.work-video {
  margin-top: 0rem;
}
.work-video-restricted {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.work-video-restricted::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #171717bc;
  backdrop-filter: blur(5px);
  content: " ";
  border-radius: 15px;
  z-index: 1;
}
.work-video::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1717175a;
  content: " ";
  border-radius: 15px;
  z-index: 1;
}
.work_image {
  margin-top: 1rem;
}
video {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 15px;

  cursor: pointer; /* Indicate that the video is clickable */
}
.rotate {
  animation: spin 2s linear infinite;
}
.next-project-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #ececec;
  height: 80%;
  width: 100%;
  justify-self: center;
  align-self: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
}

.next-project-section button {
  background: none;
  color: #171717;
  font-size: 2vw;
  border: 0;
  position: relative;
  top: 4.5vw;
  outline: none;
}
@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@media (max-width: 426px) {
  .work_description {
    margin-top: 0rem !important;
  }
}
@media (max-width: 351px) {
  .video_buble {
    width: 7rem !important;
    height: 6rem !important;
    font-size: 2rem;
  }
  .works-section-page {
    padding: 2rem 1rem !important;
  }
  .work_name h1 {
    width: 100% !important;
  }
}

@media (max-width: 1206px) {
  .works-section {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* .navLink {
    display: flex;
    justify-self: center;
    align-self: center;
  } */
  .work_image,
  .work-video {
    position: relative;

    display: flex;
    justify-self: center;
    align-self: center;
    height: 20rem;

    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .tool {
    color: #171717;
    font-size: 1.3rem;
  }
  .work_description {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    gap: 2rem;
  }
  .tools-section::after,
  .addition::after,
  .description::after {
    left: 2vw;
    top: -0.75rem;
    font-size: 0.8rem;
    color: #171717;

    padding: 0 0.5rem;
  }

  .work_name {
    position: relative;
    color: #171717;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.8fr 1fr;
    height: 100%;
    width: 100%;
    display: flex;
    justify-self: center;
    align-self: center;
  }
  .work_name h1 {
    font-size: 3.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.3;
  }

  .line {
    background: rgb(206, 206, 206);
    position: relative;
    width: 80%;
    height: 1px;
    top: 1vw;
  }

  .video_buble {
    position: relative;
    right: 0;
    width: 12rem;
    height: 8rem;
    background: #0466c8;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    overflow: hidden;
  }

  .description {
    font-size: 0.75rem;
    border: 1px solid rgb(206, 206, 206);
    padding: 2vw 5vw;
    color: #171717;
    border-radius: 15px;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addition {
    font-size: 0.75rem;
  }

  .rotate {
    animation: spin 2s linear infinite;
  }
  .next-project-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #ececec;
    width: 100%;
    /* height: 13rem; */

    justify-self: center;
    align-self: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-self: center;
    align-self: center;
  }

  .next-project-section button {
    background: none;
    color: #171717;
    font-size: 1.3rem;
    border: 0;
    position: relative;
    top: 3.5rem;
    outline: none;
  }
  .next-work {
    width: 15rem;
    height: 11rem;
    border-radius: 15px;
    background-position: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 100%;
    position: relative;
    top: 4rem;
    transition: all 0.3s ease-in-out;
  }
  .next-project-section:hover .next-work {
    position: relative;
    transition: all 0.3s ease-in-out;
    top: unset;
    top: 2rem !important ;
  }
}

@media (max-width: 862px) {
  .work_name h1 {
    font-size: 3rem;
    position: absolute;
  }

  .works-section-page {
    padding: 2rem 1.5rem;
  }
  .decor {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 4.5rem;
    margin-left: unset;
    width: 100%;
  }
  .video_buble {
    width: 8rem;
    height: 8rem;
  }

  .work_description {
    font-family: "Noto Sans", sans-serif;

    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    color: #171717;
    line-height: 1.5;
    gap: 1rem;
    margin-top: 2rem;
  }
  .first-row {
    display: flex;
    flex-direction: column;
    display: flex;
    justify-content: unset;
    gap: 2rem;
    width: 100%;
  }
  .second-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    align-items: baseline;
    margin-top: 1rem;
    width: 100%;
  }
  .work_image {
    margin-top: 1rem;
  }

  .first-row .description,
  .addition {
    width: 100%;
    height: 50%;
    border-radius: 15px;
    line-height: 2;
    padding: 2rem;
  }
  .second-row .tools-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-radius: 15px;
    line-height: 2;
    padding: 1rem 2rem;
  }
}

@media (max-width: 701px) {
  .next-project-section button {
    background: none;
    color: #171717;
    font-size: 1.3rem;
    border: 0;
    position: relative;
    top: 3.5rem;
    outline: none;
  }

  .next-work {
    position: absolute;
    /* width: 70%;
    height: 100%; */
    border-radius: 15px;
    background-position: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 100%;
    position: relative;
    top: 4rem;
    transition: all 0.3s ease-in-out;
  }
  .next-project-section:hover .next-work {
    position: relative;
    transition: all 0.3s ease-in-out;
    top: 2rem;
  }
  .next-project-section {
    position: relative;
    justify-self: center;
    align-self: center;
    padding: 0;
    bottom: 2rem;
  }
}

/* @media (max-width: 699px) {
  .work_name {
    position: relative;
    height: 100%;

    display: flex;
    justify-self: center;
    align-self: center;

    color: #171717;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .work_name h1 {
    position: relative;
    max-width: 80%;
    display: flex;
    justify-self: flex-start;
  }
} */
@media (max-width: 573px) {
  .video_buble {
    width: 9rem;
    height: 7.5rem;
    font-size: 2rem;
  }
}

@media (width > 1400px) {
  .works-wrapper .copyright_txt {
    font-size: clamp(0.9rem, 1.2vw, 1.4rem) !important;
  }
  .next-work {
    width: 19rem;
    height: 13vw;
  }
  .video_buble {
    position: relative;
    right: 0;
    width: 13rem;
    height: 20vh;

    background: #0466c8;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
  }
  .works-section-page .works {
    grid-gap: 4rem;
  }
}

@media (max-width: 496px) {
  .work_name h1 {
    font-size: clamp(2.8rem, 3vw, 3vw);
    width: 81% !important;
  }
  .next-work {
    position: absolute;
    width: 70%;

    border-radius: 15px;
    background-position: 100% 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 100%;
    position: relative;
    top: 5rem;
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  .next-project-section:hover .next-work {
    transition: all 0.3s ease-in-out;
    top: 3rem !important;
  }
}

/* @media (max-width: 467px) {
 
  .decor {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 6.5rem;
    margin-left: unset;
    width: 100%;
  }
  .work_description {
    margin-top: 2.5rem;
  }

}
 */
@media (hover: hover) {
  .next-project-section:hover .next-work {
    transition: all 0.3s ease-in-out;
    top: 1rem;
  }
  .next-project-section:hover .next-work {
    transition: all 0.3s ease-in-out;
    top: 1vw;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
}

@media (hover: none) {
  .icon:hover {
    color: inherit;
    transform: none;
  }

  .video-container button:hover .close-video-btn {
    color: #ececec;
  }

  .video-container button:hover::before {
    width: 1px;
    height: 1px;
  }

  .work-video button:hover .play-btn {
    color: #171717;
    transition: none;
  }

  .work-video button:hover::before {
    width: 0;
    height: 0;
  }

  .next-project-section:hover .next-work {
    transition: unset;
    top: 4rem;
  }
}

@media (max-width: 996px) {
  .video-container .video-box {
    width: 95%;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  display: flex;
  justify-self: center;
  align-self: center;
  position: relative;
  margin: 0 auto;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}

.works-wrapper .copyright_txt {
  color: #171717;
  font-family: "Noto Sans", sans-serif;
  top: 2rem;
}

.image-section .work_image:nth-child(2) {
  margin-top: 2rem;
}
